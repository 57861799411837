import React, { Component } from "react";
import Select from "react-select";
import "../../scss/addQ/AddCategory.scss";
import { getTagsList } from "../../actions/questionActions.js";
import Error from "../error.js";

export class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      selectedCategories: [],
      error: "",
    };
    this.fillCategoryList();
    this.setSelectedCategories = this.setSelectedCategories.bind(this);
  }

  fillCategoryList() {
    getTagsList().then((res) => {
      // Creating a group of categories for each parent category (that has any subcategories) 
      // with the parent as the label and children as the group
      const othersGroup = { label: "Others", options : []}
      let groupedOptions = []
      res.data.forEach((cat) => {
        if (cat.subcategories.length > 0){
          groupedOptions.push({label: cat.title, options: cat.subcategories})
        // If this category has no parent, add it to 'Others' group
        }else if(!cat.parent_category_id){
          othersGroup.options.push(cat)
        }
      })
      if (othersGroup.options.length > 0){
        groupedOptions.push(othersGroup)
      }
      this.setState({ categories: groupedOptions });
    });
  }

  componentDidMount() {
    // Setting default selected categories when used in edit mode (when props.selectedCategories is not empty)
    if (!this.props.isFilter) {
      this.setState({
        selectedCategories: this.props.selectedCategories.map(
          (categoryText) => ({
            id: categoryText.tagId,
            title: categoryText.answer_text,
            subcategories: [],
          })
        ),
      });
    }
  }

  setSelectedCategories(event) {
    if (this.props.isFilter) {
      this.props.setCategories(
        event ? event.map((category) => category.title) : []
      );
      return this.setState({
        selectedCategories: event ? event : [],
      });
    } else {
      // Checking if the selected list of categories (event) has a length of less than 11
      if (!event || event.length < 11) {
        this.setState({ error: "" });
        this.props.setCategories(
          event
            ? event.map((category, index) => ({
                answer_text: category.title,
                is_correct: false,
                id: index,
                tagId: category.id,
              }))
            : []
        );
        return this.setState({
          selectedCategories: event ? event : [],
        });
      } else {
        this.setState({
          error: "Maximum number of selected categories reached!",
        });
      }
    }
  }

  

  

  render() {

    const formatGroupLabel = data => (
      <div className="category-group">
        <span>{data.label}</span>
        <span className="category-group-badge">{data.options.length}</span>
      </div>
    );

    return (
      <div className="add-category">
        {this.state.error === "" ? (
          ""
        ) : (
          <Error msg={this.state.error} type="error" />
        )}
        <Select
          value={this.state.selectedCategories}
          isMulti
          placeholder="Category"
          closeMenuOnSelect={false}
          options={this.state.categories}
          getOptionLabel={(category) => category.title}
          getOptionValue={(category) => category.title}
          formatGroupLabel={formatGroupLabel}
          className="category-multi-select"
          classNamePrefix="select"
          onChange={this.setSelectedCategories}
        />
      </div>
    );
  }
}

export default AddCategory;
