import React, { Component, createRef } from "react";
import "../../scss/applications.scss";
import { Link, withRouter } from "react-router-dom";
import { API } from "../../api";
import "../../scss/applications_interviewer/pagination.scss";
import emptyStateSVG from "../../images/emptyState.svg";
import queryString from "query-string";
//all applications (already marked and need to be corrected)
export class InterviewerApplications extends Component {
  constructor(props) {
    super(props);
    this.unmarkedButton = createRef();
    this.markedButton = createRef();
    this.state = {
      applications: [],
      current_attempts: 0,
      total_attempts: 0,
      per_page: 4,
      current_page: 1,
      total: 0,
      onPageMarkedAttempts: 0,
      showMarked: false,
      evaluated_applications: [],
      marked_applications_loaded: false,
      unmarked_applications_loaded: false,
    };
  }

  componentDidMount() {
    //check url marked or unmarked

    if (this.props.wantMarked === true) {
      this.getMarkedExams();
    }
    //get list of applications from server
    API.get(`/attempts/evaluate`).then((res) => {
      let attempts = res.data.attempts;
      let array = [];
      for (let i = 0; i < attempts.length; i++) {
        let current = {
          id: attempts[i].id,
          status: attempts[i].status,
          position: attempts[i].exam.exam_title,
          duration: attempts[i].exam.actual_time_in_minutes,
          numberOfQuestions: attempts[i].exam.questions_count,
          isExpired: false,
        };
        array.push(current);
      }
      this.setState({
        applications: array,
        current_attempts: res.data.current_attempts,
        total_attempts: res.data.total_attempts,
        unmarked_applications_loaded: true,
      });
    });
    this.evaluated_exams(1);
  }
  //get list of evaluated exams
  evaluated_exams = (pageNumber) => {
    API.get(
      `/attempts/evaluated?page=${pageNumber}&per_page=${this.state.per_page}`
    ).then((res) => {
      let attempts = res.data.attempts;
      let array = [];
      for (let i = 0; i < attempts.length; i++) {
        let current = {
          id: attempts[i].id,
          status: attempts[i].status,
          position: attempts[i].exam.exam_title,
          duration: attempts[i].exam.actual_time_in_minutes,
          numberOfQuestions: attempts[i].exam.questions_count,
          isMarked: true,
        };
        array.push(current);
      }

      this.setState({
        evaluated_applications: array,
        total: res.data.total_attempts,
        current_page: pageNumber,
        last_page: Math.ceil(res.data.total_attempts / this.state.per_page),
        onPageMarkedAttempts: res.data.attempts.length,
        marked_applications_loaded: true,
      });
    });
  };
  refresh_evaluated_exams(page) {
    this.setState({ current_page: page });
    this.evaluated_exams(page);
  }
  getMarkedExams = () => {
    this.unmarkedButton.current.className = "tab-button";
    this.markedButton.current.className = "tab-button selectedTab";

    this.setState({ showMarked: true });
  };
  render() {
    const pageNumbers = [];
    for (let i = 1; i <= this.state.last_page; i++) {
      pageNumbers.push(i);
    }
    const pages = pageNumbers.map((number, i) => {
      let classes = this.state.current_page === i + 1 ? "active" : "";
      let temp;
      if (this.state.total >= 1) {
        if (
          number === this.state.total ||
          (number >= this.state.current_page - 1 &&
            number <= this.state.current_page + 1)
        ) {
          return (temp = (
            <span
              key={number}
              className={classes}
              onClick={(number) => this.refresh_evaluated_exams(i + 1)}
            >
              {" "}
              {number}{" "}
            </span>
          ));
        }
      } 
      return temp;
    });
    const back_arrows =
      this.state.current_page === 1 ? (
        ""
      ) : (
        <div>
          <svg
            id="rotate"
            onClick={() => this.refresh_evaluated_exams(1)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
          </svg>
          <svg
            onClick={
              this.state.current_page === 1
                ? ""
                : () =>
                    this.refresh_evaluated_exams(this.state.current_page - 1)
            }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
          </svg>
        </div>
      );
    const front_arrows =
      this.state.current_page === this.state.last_page ? (
        ""
      ) : (
        <div>
          <svg
            onClick={
              this.state.current_page === this.state.last_page
                ? ""
                : () =>
                    this.refresh_evaluated_exams(this.state.current_page + 1)
            }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
          </svg>
          <svg
            onClick={() => this.refresh_evaluated_exams(this.state.last_page)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
          </svg>
        </div>
      );

    const applications = this.state.applications.map((item, i) => (
      <div
        className={"application " + (item.isMarked ? "yellow" : "red")}
        key={i}
      >
        <section>
          <span className="label">ID</span>
          <span className="value">{item.id}</span>
        </section>
        <section>
          <span className="label">Exam Title</span>
          <span className="value">{item.position}</span>
        </section>
        <section>
          <span className="label">Status</span>
          <span className="value">{item.status}</span>
        </section>
        <section>
          <span className="label">Exam length</span>
          <span className="value">{item.numberOfQuestions + " Questions"}</span>
        </section>
        <section className="submit-section">
          <span className="label submit-label">Submit</span>
          <button
            className="submit-btn"
            disabled={item.isExpired ? "disabled" : ""}
            onClick={() => this.props.redirect(item.id)}
          >
            Mark Exam
          </button>
        </section>
      </div>
    ));

    const evaluatedApplications = this.state.evaluated_applications.map(
      (item, i) => (
        <div
          className={"application " + (item.isMarked ? "yellow" : "red")}
          key={i}
        >
          <section>
            <span className="label">ID</span>
            <span className="value">{item.id}</span>
          </section>
          <section>
            <span className="label">Exam Title</span>
            <span className="value">{item.position}</span>
          </section>
          <section>
            <span className="label">Status</span>
            <span className="value">{item.status}</span>
          </section>
          <section>
            <span className="label">Exam length</span>
            <span className="value">
              {item.numberOfQuestions + " Questions"}
            </span>
          </section>
        </div>
      )
    );

    const applicationsNumberIndicator = (
      <span className="applications-number">
        Showing{" "}
        {this.state.current_attempts === 1
          ? " 1 application "
          : this.state.current_attempts + " applications "}
        out of {this.state.total_attempts}
      </span>
    );
    const MarkedapplicationsNumberIndicator = (
      <span className="applications-number">
        Showing{" "}
        {this.state.onPageMarkedAttempts === 1
          ? " 1 marked application "
          : this.state.onPageMarkedAttempts + " marked applications "}
        out of {this.state.total}
      </span>
    );

    return (
      <div className="applications-container">
        <div className="button-container">
          <Link to="/me/applications">
            <button
              ref={this.unmarkedButton}
              onClick={() => {
                this.markedButton.current.className = "tab-button";
                this.unmarkedButton.current.className =
                  "tab-button selectedTab";
                this.setState({ showMarked: false });
              }}
              className="tab-button selectedTab"
            >
              {" "}
              Applications{" "}
            </button>
          </Link>
          <Link to="/me/applications?marked=true">
            <button
              ref={this.markedButton}
              onClick={this.getMarkedExams}
              className="tab-button"
            >
              {" "}
              Marked Applications{" "}
            </button>
          </Link>
        </div>

        <div className="applications-page">
          {this.state.showMarked === false
            ? applicationsNumberIndicator
            : MarkedapplicationsNumberIndicator}
          {this.state.applications.length > 0 &&
            this.state.showMarked === false &&
            applications}
          {this.state.applications.length === 0 &&
            this.state.showMarked === false &&
            this.state.unmarked_applications_loaded === true && (
              <div className="emptyState">
                <div className="text">
                  <h1>There are no more applications</h1>
                  <p>We'll notify you when a new application is submitted</p>
                </div>
                <img alt="avatar" src={emptyStateSVG} />
              </div>
            )}
          {this.state.evaluated_applications.length > 0 &&
            this.state.showMarked === true &&
            evaluatedApplications}
          {this.state.evaluated_applications.length === 0 &&
            this.state.showMarked === true &&
            this.state.marked_applications_loaded === true && (
              <div className="emptyState">
                <div className="text">
                  <h1>There are no more applications</h1>
                  <p>We'll notify you when a new application is submitted</p>
                </div>
                <img alt="avatar" src={emptyStateSVG} />
              </div>
            )}
          {/* Placeholder before loading applications */}
          {(this.state.showMarked === true &&
            this.state.marked_applications_loaded === false) ||
            (this.state.showMarked === false &&
              this.state.unmarked_applications_loaded === false && (
                <ApplicationPlaceholder />
              ))}
        </div>
        {this.state.evaluated_applications.length > 0 &&
          this.state.showMarked === true && (
            <div className="pages">
              {back_arrows}
              {pages}
              {front_arrows}
            </div>
          )}
      </div>
    );
  }
}
//the candidate's applications (exams)
class CandidateApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      applicationsLoaded: false
    };
  }
  componentDidMount() {
    //get list of candidate's applications from server
    API.get(`/attempts`).then((res) => {
      let attempts = res.data.attempts;
      let array = [];
      for (let i = 0; i < attempts.length; i++) {
        let current = {
          id: attempts[i].id,
          status: attempts[i].status,
          position: attempts[i].exam.exam_title,
          duration: attempts[i].exam.actual_time_in_minutes,
          numberOfQuestions: attempts[i].exam.questions_count,
          startDate: attempts[i].exam.startDate,
          endDate: attempts[i].exam.endDate,
          examType: attempts[i].exam.exam_type,
          isExpired: false,
          remaining_time: attempts[i].remaining_time
        };
        array.push(current);
      }
      this.setState({ applications: array }, () => {
        this.setState({applicationsLoaded: true})
      });
    }).catch((err) => {
      this.setState({applicationsLoaded: true})
    });
    this.interval = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount(){
    clearInterval(this.interval)
  }

  tick(){
    let applications = this.state.applications;
    
    for (let i = 0; i < applications.length; i++) {
      //Time mechanism
      let time = applications[i].remaining_time
      if (applications[i].examType == "restricted" || time <= 0 || applications[i].status == 'pending'){
        continue;
      }
      applications[i].remaining_time -= 1
    }

    this.setState({applications: applications });
    
  }
  buttonNameAndActive(item) {
    let buttonName = "";
    let buttonDisabled = false;

    if (item.startDate && item.startDate > new Date().getTime() / 1000) {
      buttonName = "Not Started";
      buttonDisabled = true;
    } else if (item.endDate && item.endDate < new Date().getTime() / 1000 && item.status == 'pending') {
      buttonName = "Expired";
      buttonDisabled = true;
    } else if (item.status != 'pending' && item.status != 'draft') {
      buttonName = "Submitted";
      buttonDisabled = true;
    } else if (item.status == "pending") {
      buttonName = "Start Exam"
      buttonDisabled = false;
    } else {
      // in this case the status is draft
      if ( (item.remaining_time > 0 || item.examType == "restricted") && (!item.endDate || item.endDate > new Date().getTime() / 1000) ) {
        buttonName = "Continue";
        buttonDisabled = false;
      } else {
        buttonName = "Submitted";
        buttonDisabled = true;
      }
    }
    return {buttonName, buttonDisabled}
  }
  render() {
    const applicationsPlaceholder = [...Array(3)].map((e, i) => (
      <div key={i} className="application">
        {[...Array(4)].map((e, i) => (
        <section key={i} className="placeholder-section">
          <span className="label placeholder-label"></span>
          <span className="value placeholder-value"></span>
        </section>
        ))}
      </div>
    ))

    const emptyState = (
      <div className="emptyState">
        <div className="text">
          <h1>There are no applications yet</h1>
          <p>We'll notify you when you're invited to an exam</p>
        </div>
        <img alt="avatar" src={emptyStateSVG} />
      </div>
    );
    const applications = this.state.applications.map((item, i) => (
      <div
        className={"application " + (item.isExpired ? "yellow" : "red")}
        key={i}
      >
        <section>
          <span className="label">Position</span>
          <span className="value">{item.position}</span>
        </section>
        <section>
          <span className="label">Status</span>
          <span className="value">{item.status}</span>
        </section>
        <section>
          <span className="label">Duration</span>
          <span className="value">
            {item.examType == "restricted"
              ? "Not Specified"
              : Math.ceil(item.duration / 60) + " Minutes"}
          </span>
        </section>
        <section>
          <span className="label">Exam length</span>
          <span className="value">{item.numberOfQuestions + " Questions"}</span>
        </section>
        <section className="submit-section">
          <span className="label submit-label">Submit</span>
          <button
            className="submit-btn"
            disabled={this.buttonNameAndActive(item).buttonDisabled}
            onClick={() => this.props.redirect(item.id)}
          >
            {this.buttonNameAndActive(item).buttonName}
          </button>
          {item.examType != "restricted" &&
          item.status == "draft" &&
          item.remaining_time > 0 ? (
            <div className="remaining-time-under-submit-button">
              <span class="material-icons">update</span>
              <span className="time">
                {String(item.remaining_time).secsToHHMMSS().hours}:
                {String(item.remaining_time).secsToHHMMSS().minutes}:
                {String(item.remaining_time).secsToHHMMSS().seconds}
              </span>
            </div>
          ) : null}
        </section>
      </div>
    ));
    return (
      <div className="applications-page">
        {this.state.applicationsLoaded
          ? this.state.applications.length > 0
            ? applications
            : emptyState
          : <ApplicationPlaceholder />}
      </div>
    );
  }
}
class Submissions extends Component {

  requestMarkedExams = false;
  componentWillMount() {
    if (queryString.parse(this.props.location.search).marked == "true") {
      this.requestMarkedExams = true;
    }
  }
  //redirect candidate to exam
  redirectCandidate = (id) => {
    this.props.history.push("/me/attempt/" + id);
  };
  //redirect interviewer to mark the exam
  redirectInterviewer = (id) => {
    this.props.history.push("/me/mark/" + id);
  };
  render() {
    return JSON.parse(localStorage.userObject).type == "Candidate" ? (
      <CandidateApplications redirect={this.redirectCandidate} />
    ) : (
      <InterviewerApplications
        wantMarked={this.requestMarkedExams}
        redirect={this.redirectInterviewer}
      />
    );
  }
}


export class ApplicationPlaceholder extends Component {
  render() {
    return (
      <React.Fragment>{
          [...Array(3)].map((e, i) => (
            <div key={i} className="application">
              {[...Array(4)].map((e, i) => (
              <section key={i} className="placeholder-section">
                <span className="label placeholder-label"></span>
                <span className="value placeholder-value"></span>
              </section>
              ))}
            </div>
          ))
        }
      </React.Fragment>
    )
  }
}

export default withRouter(Submissions);
