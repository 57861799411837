import React, {Component} from "react";
import "../scss/FormElements.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


class InputSection extends Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false };
  }
  render() {
    return (
      <div className="inputContainer elem">
        <input autoComplete={this.props.autocomplete === undefined ? "" : "new-password"} defaultValue={this.props.defaultValue === "" ? "" : this.props.defaultValue}
        placeholder={this.props.placeHolder} id={this.props.id} value={this.props.val} disabled={this.props.disabled === undefined ? false : true} name={this.props.name} type={this.props.type} onChange={(event) => { this.props.onChange(event, this.props.name) }} />
        <label>
          <span className="label-content">
            {this.props.label === "" || this.props.label === undefined
              ? this.props.type
              : this.props.label}
          </span>
        </label>
        <br />
      </div>
    );
  }
}

class SelectMenu extends Component {
 
  render() {
    return (
      <div className="selectContainer">
        <select onChange={(event) => { this.props.onChange(event) }}>
        {this.props.options.map((option,i) => {
          if(this.props.selected === option.title){
            return <option key={i} selected="selected" value={option.id}>{option.title}</option>
          }else{
            return <option key={i} value={option.id}>{option.title}</option>
          }
        })}
        </select>
      </div>
    );
  }
}

class CheckboxSection extends Component {

  render() {
    return (
      <div className="checkbox">
        <input type="checkbox" id="check" onChange={(event) => { this.props.onChange(event) }}/>
        <label>
          <img alt="eSpace" src="checkmark.png" />
        </label>
        <p>Remember me</p>
      </div>
    );
  }
}

class FormBtn extends Component {
    constructor(props) {
    super(props);
    this.buttonHandler= this.buttonHandler.bind(this);
  }


  buttonHandler(event){
    event.preventDefault();
    if(this.props.onClick != undefined){
      this.props.onClick();
    }
  }

  render() {
    return (
      <button disabled={this.props.loading} onClick={(event) => { this.buttonHandler(event) }} className="submitBtn">
        {
          this.props.loading ?
          (<Loader
            type="ThreeDots"
            color="#FFFFFF"
            height={27}
            width={27}
          />):
          (
            this.props.label
          )
        }
      </button>
    );
  }
}

export default InputSection;
export { CheckboxSection, FormBtn, SelectMenu };
