import React from 'react'
class Validation extends React.Component{
   static validateLength(array){
    if(array.length==0){
      return false;
    }
    return true;
 }
 static validateArray(array,type){
     if(type=="choices"){
        let valid_choices = 0;
        array.forEach((element) => {
            if(element['_destroy'] === undefined){
                valid_choices++;
            }
        });
        if(valid_choices < 2){
            return false;
        }
     }
     else{
         if(array.length<1){
             return false;
         }
     }
     return true;
 }
}
export default Validation;