import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Questions from "../components/dashboard/myQuestions";
import Submissions from "../components/dashboard/submissions";
import Navigation from "../components/dashboard/navigation";
import ExamsList from "../components/dashboard/examsList";
import Profile from "../components/profile/main";
import NormalExam from "../components/exam/normalExam";
import RestrictedExam from "../components/exam/restrictedExam";
import Guide from "../components/exam/exam-guide/guide";
import MarkExam from "../components/dashboard/markExam";
import SingleExam from "../components/addExam/singleExam";

import "../scss/sub.scss";
import ExamSubmitted from "../components/exam/exam-guide/ExamSubmitted";

//User's dashboard routes
//routes that start with /me
class DashboardRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.id = "";
    //navigation bar info
    if (!localStorage.client) {
      this.state = {
        first_name: "first_name",
        avatar: "avatar",
        profile_hidden: true,
      };
    } else {
      this.state = {
        first_name: JSON.parse(localStorage.userObject).first_name,
        avatar: JSON.parse(localStorage.userObject).avatar.url,
        profile_hidden: true,
      };
    }
    this.toggleProfile = this.toggleProfile.bind(this);
    this.updateNavigation = this.updateNavigation.bind(this);
  }
  toggleProfile() {
    this.setState({ profile_hidden: !this.state.profile_hidden });
  }

  updateNavigation(first_name, avatar) {
    this.setState({ first_name: first_name, avatar: avatar });
  }

  render() {
    //get exam id
    this.id = this.props.location.pathname.includes("/me/attempt")
      ? this.props.location.pathname.substring(12)
      : "0";
    this.id = this.id.includes("/") ? this.id.split("/")[0] : this.id;
    //set a variable in local storage to redirect user to guide or home page after login
    if (this.props.location.pathname.includes("/me/attempt")) {
      localStorage.setItem("redirect_url", this.props.location.pathname);
    }
    if (!localStorage.client) {
      this.props.history.replace("/");
      return null;
    } else {
      //redirect interviewer to my questions and candidate to applications page
      if (!this.props.location.pathname.includes("/me/attempt")) {
        if (JSON.parse(localStorage.userObject).type === "Candidate") {
          localStorage.setItem("redirect_url", "/me/applications");
        } else {
          localStorage.setItem("redirect_url", "/me/my_questions");
        }
      }
    }
    return (
      <div className="content">
        {!this.props.location.pathname.includes("/me/attempt") && (
          <Navigation
            toggleProfile={this.toggleProfile}
            first_name={this.state.first_name}
            avatar={this.state.avatar}
          />
        )}
        <Profile
          hidden={this.state.profile_hidden}
          toggleProfile={this.toggleProfile}
          updateNavigation={this.updateNavigation}
        />
        <Switch>
          {/* keyProp and key are used to make react-router reloads Questions route even if it's the current route */}
          <Route
            path="/me/applications"
            keyProp={10000}
            key={Math.random(1000)}
            component={Submissions}
            exact
          />
          <Route
            path="/me/my_questions"
            render={(props) => (
              <Questions {...props} keyProp={10000} key={Math.random(1000)} />
            )}
          />
          <Route
            path="/me/exams_list"
            keyProp={10000}
            key={Math.random(1000)}
            component={ExamsList}
            exact
          />
          <Route
            exact
            path="/me/attempt/:id/normalexam"
            component={!localStorage["visitedGuide" + this.id] ? Guide : NormalExam}
          />
          <Route
            exact
            path="/me/attempt/:id/restrictedexam"
            component={!localStorage["visitedGuide" + this.id] ? Guide : RestrictedExam}
          />
          <Route
            exact
            path="/me/attempt/submitted"
            component={ExamSubmitted}
          />
          <Route path="/me/attempt/:id" component={Guide} />
          <Route path="/me/mark/:id" component={MarkExam} />
          <Route path="/me/exam/:id" component={SingleExam} />
          <Redirect
            path="*"
            to={
              JSON.parse(localStorage.userObject).type === "Candidate"
                ? "/me/applications"
                : "/me/my_questions"
            }
          />
        </Switch>
      </div>
    );
  }
}

export default DashboardRoutes;
