import React, { Component } from 'react';
import "../../scss/addQ/modal.scss";

class Radio extends Component {
  constructor(props) {
    super(props);
    // unique_id is used to differentiate between different instances of Radio component rendered in the same page
    this.unique_id = Math.random().toString(36).substring(7);
  }
  handleDifficultyAndTime = (e) => {
    if (e.target.name === "Difficulty") {
      this.props.difficulty(e.target.value, e.target.name.toLowerCase());
    } else if (e.target.name === "Time") {
      this.props.time(e.target.value, e.target.name.toLowerCase());
    }
    else if (e.target.name === "Type") {
      this.props.type(e.target.value, e.target.name.toLowerCase());
    }
  }
  render() {
    const data = this.props.data.map((item, i) => (
      <React.Fragment>
        <input type="radio" name={this.props.label} value={item} id={this.props.label + i + '-' + this.unique_id} onClick={(e) => this.handleDifficultyAndTime(e)} checked={this.props.active == item ? "checked" : ""} />
        <label for={this.props.label + i + '-' + this.unique_id}>{item}</label>
      </React.Fragment>
    ));
    return (
      <div className="radio">
        <p>Pick {this.props.label}</p>
        <div className="radio-choices">
          {data}
        </div>
      </div>
    );
  }
}

export default Radio;
