import React, { Component } from 'react';
import '../../../scss/exam/guide.scss';
import guidePic from '../../../images/guide-pic.png';
import { API } from '../../../api';
class Guide extends Component {
  constructor(props) {
    super(props);
    //set guide link in local storage so that the user redirects to it after log in 
    localStorage.setItem('redirect_url', this.props.location.pathname);
    this.props.history.replace("/me/attempt/" + this.props.match.params.id.toString());
    this.state = {
      user: "",
      title: "",
      numOfQuestions: 0,
      duration: 0,
    };
    //redirects to exam if the guide has been visited
    if (localStorage["visitedGuide" + this.props.match.params.id.toString()]) {
      this.props.history.push(this.props.location.pathname + localStorage["visitedGuide" + this.props.match.params.id.toString()]);
    }
    else {
      const id = this.props.match.params.id.toString();
      //get exam info from server
      API.get('attempts/' + id + '/guide').then((res) => {
        const first_name = JSON.parse(localStorage.userObject).first_name.charAt(0).toUpperCase() + JSON.parse(localStorage.userObject).first_name.slice(1);
        const last_name = JSON.parse(localStorage.userObject).last_name.charAt(0).toUpperCase() + JSON.parse(localStorage.userObject).last_name.slice(1);
        this.setState({
          title: res.data.exam.exam_title,
          duration: res.data.exam.actual_time_in_minutes,
          user: first_name + " " + last_name,
          numOfQuestions: res.data.exam.questions_count,
          examType: res.data.exam.exam_type
        });
      }).catch((err) => {
        this.props.history.push('/');
      });
    }
  }
  //sets a variable in local storage to indicate that the guide for this exam has been visited and redirects to exam
  handleClick = (e) => {
    const exam_link = this.state.examType == "restricted" ? "/restrictedexam" : "/normalexam"
    localStorage.setItem("visitedGuide" + this.props.match.params.id.toString(), exam_link);
    this.props.history.push(this.props.location.pathname + exam_link)
  }
  render() {
    const restrictedInstructions = this.state.examType == "restricted" ? ( 
    <div >
      <li>{"We will show you one question at a time."}</li>
      <li>{"You will have to answer each question within its specified time."}</li>
      <li>{"If the time of the question passed, we will automatically submit your answer."}</li>
      <li>{"Press next to submit your answer and get the next question."}</li>
    </div>
    ):(
      <div >
      <li>You have the ability to scroll through questions, go back to a question and change your answers before submission.</li>
      <li>{"We will show you all the questions of the exam to solve during the exam time showed."}</li>
      <li>{"Your answers will be saved automatically every 10 seconds."}</li>
      <li>{"You can save your answer manually anytime by pressing on save answers button."}</li>
    </div>
    );
    return (
      <div className="guide-page">
        <div className="guide-container">
          <div className="guide-info">
            <section className="info">
              <span>{this.state.user}</span>
              <h1 className="exam-title">{this.state.title}</h1>
              <span> {this.state.numOfQuestions + " Questions"} <strong> </strong> {this.state.examType === "restricted" ? "" : "| " + Math.ceil((this.state.duration / 60)) + " minutes"}  </span>

            </section>
            <section className="instructions">
              <h1 className="instructions-title">Before you begin ..</h1>
              <ul>
                <li>Make sure you're in a quiet place with good internet connection.</li>
                <li>Take a deep breath and stay calm. We believe in you!</li>
                {restrictedInstructions}
              </ul>
            </section>
            <section className="start">
              <button onClick={this.handleClick}>
                <span>Start Exam</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 12l-18 12v-24z" /></svg>
              </button>
              <a href="/">Back to home</a>
            </section>
          </div>
        </div>
        <img className="image_guide" src={guidePic} />
      </div>
    );
  }
}

export default Guide;
