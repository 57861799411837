import React, { Component } from "react";
import Level from "./level";
import "../../scss/myQuestions/card.scss";

class QuestionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      choices: [{ choice_text: "Loading", is_correct: false }],
    };
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.isLightboxOpen === nextProps.isLightboxOpen || this.props.isLightboxOpen === undefined;
  }

  handleShow() {
    this.setState({ isExpanded: true });
    this.props.choices(this.props.id).then((res) => {
      if (this.props.type === "mcq") {
        let choices = res.data.choices_attributes;
        this.setState({ choices: choices });
      } else if (this.props.type === "true_false") {
        let correct = res.data.t_f_answer ? true : false;
        let choices = [
          { choice_text: "True", is_correct: correct },
          { choice_text: "False", is_correct: !correct },
        ];
        this.setState({ choices: choices });
      }
    });
  }
  handleHide() {
    this.setState({ isExpanded: false });
  }
  componentWillReceiveProps() {
    this.setState({
      isExpanded: false,
      choices: [{ choice_text: "Loading", is_correct: false }],
    });
  }
  toggleExpand() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }
  toggleEdit() {
    let question = {
      id: this.props.id,
      type: this.props.type,
      content: this.props.content,
      choices: this.state.choices,
      difficulty: this.props.difficulty,
      time: this.props.time,
      tags: this.props.tags,
    };
    this.props.editQuestion(question);
  }
  render() {
    let choices = this.state.choices.map((item, i) => (
      <li key={i} className={item.is_correct ? "correct" : ""}>
        {item.choice_text}
      </li>
    ));
    // const showSVG = (this.state.isExpanded?
    //   <svg className="expand" onClick={this.handleHide.bind(this)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/></svg>
    // : <svg className="expand" onClick={this.handleShow.bind(this)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.199.02-.393.057-.581 1.474.541 2.927-.882 2.405-2.371.174-.03.354-.048.538-.048 1.657 0 3 1.344 3 3zm-2.985-7c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 12c-2.761 0-5-2.238-5-5 0-2.761 2.239-5 5-5 2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"/></svg>);
    const showSVG = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={this.state.isExpanded ? "up" : "down"}
        onClick={() => {
          this.state.isExpanded === true
            ? this.handleHide()
            : this.handleShow();
        }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
      </svg>
    );

    let content,
      tags = [];
    content =
      this.props.content.length > 100 && !this.state.isExpanded
        ? this.props.content.substring(0, 100) + "..."
        : this.props.content;
    content = content.split("\n").map((i, key) => {
      return <div key={key}>{i}</div>;
    });
    if (this.state.isExpanded) {
      if (this.props.tags.length !== 0) {
        tags = this.props.tags.map((item, i) => {
          return (
            <div className="tag" key={i}>
              {item.text}
            </div>
          );
        });
      }
    }else{
      content = this.props.content.length  > 100 ? this.props.content.substring(0,100)+ "..." : this.props.content ;
      content = content.split("\n").map((i,key) => {
        return <div key={key} style={this.props.question_image ? { width: "90%" } : { width: "98%" }}>{i}</div>;
      });
    }
    return (
      <div className={"question " + (this.state.isExpanded ? "long" : "")}>
        <header>
          <div className="left">
            <i className="material-icons person-icon">person</i>
            <span>{this.props.author}</span>
            <i className="material-icons date-icon">calendar_today</i>
            <span className="date">{this.props.created_at}</span>
          </div>

          {this.props.edit_mode ? (
            <div className="right">
              {(this.props.common_question &&
                !this.props.questions_removed_edit.find(
                  (q) => q.id === this.props.id
                )) ||
              this.props.questions_added_edit.find(
                (q) => q.id === this.props.id
              ) ? (
                <img
                  style={{ width: "20px", cursor: "pointer" }}
                  src={require("../../images/minus_sign.svg")}
                  onClick={() => this.props.remove_q_in_edit(this.props.id)}
                />
              ) : (
                <img
                  style={{ width: "20px", cursor: "pointer" }}
                  src={require("../../images/plus_sign.svg")}
                  onClick={() => this.props.add_q_in_edit(this.props.id)}
                />
              )}

              {showSVG}
            </div>
          ) : (
            <div className="right">
              {(this.props.current_user === this.props.author && !this.props.dont_show_options) && (
                <svg
                  className={this.props.inExam === false ? "" : "disabled"}
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    if (this.props.inExam === false) {
                      this.props.deleteQuestion(this.props.id);
                    }
                  }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711v2zm-7 15.5c0-1.267.37-2.447 1-3.448v-6.052c0-.552.447-1 1-1s1 .448 1 1v4.032c.879-.565 1.901-.922 3-1.006v-7.026h-18v18h13.82c-1.124-1.169-1.82-2.753-1.82-4.5zm-7 .5c0 .552-.447 1-1 1s-1-.448-1-1v-10c0-.552.447-1 1-1s1 .448 1 1v10zm5 0c0 .552-.447 1-1 1s-1-.448-1-1v-10c0-.552.447-1 1-1s1 .448 1 1v10zm13-.5c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-3.086-2.122l-1.414 1.414-1.414-1.414-.707.708 1.414 1.414-1.414 1.414.707.708 1.414-1.414 1.414 1.414.708-.708-1.414-1.414 1.414-1.414-.708-.708z" />
                </svg>
              )}

              {(this.props.current_user === this.props.author && !this.props.dont_show_options) && (
                <svg
                  className={this.props.inExam === false ? "" : "disabled"}
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    if (this.props.inExam === false) {
                      this.toggleEdit();
                    }
                  }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-5 17l1.006-4.036 3.106 3.105-4.112.931zm5.16-1.879l-3.202-3.202 5.841-5.919 3.201 3.2-5.84 5.921z" />
                </svg>
              )}

              {showSVG}
            </div>
          )}
        </header>
        <main>
        <div className="question-title-image">
            <div className="question-title-container">
              {content}
            </div>
            {this.props.question_image && !this.state.isExpanded ? (
              <span className="material-icons" style={{ color: "#e0b7b5", paddingTop: '0.5rem' }}>
                insert_photo
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="question-image-container">
            {this.props.question_image && this.state.isExpanded ? (
              <img
                className="question-image"
                onClick= {event =>
                  this.props.handleImageExpand(this.props.question_image)
                }
                src={this.props.question_image}
              />
            ) : (
              ""
            )}
          </div>

          <div className="question-choices">
            <ul>
              {choices.length !== 0 &&
              this.state.isExpanded &&
              (this.props.type === "mcq" || this.props.type === "true_false")
                ? choices
                : ""}
            </ul>
          </div>
          <div className="tags">{tags}</div>
        </main>
        <footer>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path d="M18.513 7.119c.958-1.143 1.487-2.577 1.487-4.036v-3.083h-16v3.083c0 1.459.528 2.892 1.487 4.035l3.087 3.68c.566.677.57 1.625.009 2.306l-3.13 3.794c-.937 1.136-1.453 2.555-1.453 3.995v3.107h16v-3.107c0-1.44-.517-2.858-1.453-3.994l-3.13-3.794c-.562-.681-.558-1.629.009-2.306l3.087-3.68zm-.513-4.12c0 1.101-.363 2.05-1.02 2.834l-.978 1.167h-8.004l-.978-1.167c-.66-.785-1.02-1.736-1.02-2.834h12zm-.996 15.172c.652.791.996 1.725.996 2.829h-1.061c-1.939-2-4.939-2-4.939-2s-3 0-4.939 2h-1.061c0-1.104.344-2.039.996-2.829l3.129-3.793c.342-.415.571-.886.711-1.377h.164v1h2v-1h.163c.141.491.369.962.711 1.376l3.13 3.794zm-6.004-1.171h2v1h-2v-1zm0-2h2v1h-2v-1z" />
            </svg>
            <span>{Math.floor(this.props.time / 60) + " min, " + (this.props.time - (Math.floor(this.props.time / 60)* 60)) + " sec"}</span>
            <span className="type">
              {this.props.type === "true_false" ? "T/F" : this.props.type}
            </span>
          </div>

          <Level level={this.props.difficulty} />
        </footer>
      </div>
    );
  }
}
export default QuestionCard;
