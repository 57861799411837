import React, { Component, createRef } from "react";
import { Animated } from "react-animated-css";
import "../../scss/addExam/addExam.scss";
import Error from "../error.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import DateTimePicker from "react-datetime-picker";
import { API } from "../../api";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import {capitalize} from '../../utils'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css';


 class addExam extends Component {
  constructor(props) {
    super(props);
    this.dates = createRef();
    this.time = createRef();
    this.state = {
      isOpen: false,
      exam_type: 'normal',
      exam_title: "",
      exam_description: "",
      exam_start_date: null,
      exam_end_date: null,
      exam_time: "",
      error: "",
      add_exam_dates: false,
      add_exam_time: false,
      editMode: false,
      interviewer_id: "",
      time_min: ""
    };
  }
  handleExamDates = () => {
    this.setState({ add_exam_dates: !this.state.add_exam_dates });
    this.dates.current.className === "disabledDates"
      ? (this.dates.current.className = "")
      : (this.dates.current.className = "disabledDates");
  };
  handleExamTime = () => {
    this.setState({ add_exam_time: !this.state.add_exam_time });
    this.time.current.className === "time disabledTime"
      ? (this.time.current.className = "time")
      : (this.time.current.className = "time disabledTime");
  };
  handleTimeMinutes = (e) => {
    this.setState({time_min: e.target.value}, ()=>this.setState({exam_time: (this.state.time_min * 60)}));
  };
  // handle submitting exam metadata
  handleSubmit = () => {
    //first we validate
    if (this.state.exam_title === "")
      return this.setState({ error: "Exam Title is empty." });
    if (this.state.add_exam_dates && this.state.exam_start_date === null)
      return this.setState({ error: "Exam Start Date is empty." });
    if (this.state.add_exam_time && this.state.exam_time === "")
      return this.setState({ error: "Exam Time is empty." });
    // if any of those if statements actually work the function will end.
    let exam_data={
      exam_type: this.state.exam_type,
      exam_title:this.state.exam_title,
      description:this.state.exam_description,
      start_date: this.state.add_exam_dates ? this.state.exam_start_date : null,
      end_date:this.state.add_exam_dates ? this.state.exam_end_date : null,
      calculate_time:this.state.add_exam_time?false:true,
      actual_time_in_minutes: (parseInt(this.state.time_min) * 60)   
    }
    this.setState({
      error: "",
    })
  //if in edit mode
    if(this.props.editMode){
      API.put(`/exams/${this.props.id}`,exam_data).then((res)=>{
        let exam=res.data;
        this.setState({
          exam_type: res.data.exam_type,
          exam_description: res.data.description,
          exam_title: res.data.exam_title,
          exam_start_date: new Date (res.data.start_date),
          exam_end_date: new Date (res.data.end_date),
          exam_time: res.data.calculated_time_in_minutes ? res.data.calculated_time_in_minutes: res.data.actual_time_in_minutes,
          error: "",
        })
        this.props.history.push({pathname:`/me/exam/${exam.id}`,state:exam})
        this.props.handleUpdate(res.data)
        this.setState({isOpen: false})
      }).catch((err)=>{
      this.setState({error:err.response.data.message});
      })
    }
    //creation mode
    else{
    API.post('/exams',exam_data).then((res)=>{
      let exam=res.data
      this.setState({
        exam_type: "normal",
        exam_description: "",
        exam_title: "",
        exam_start_date: null,
        exam_end_date: null,
        exam_time: "",
        error: "",
      })
      this.props.history.push({pathname:`/me/exam/${exam.id}`,state:exam})
    }).catch((err)=>{
      this.setState({error:err.response.data.message})
    })

   }
  };

  setExamType = event => this.setState({exam_type: event.value})

  handleEdit = () => {
    this.setState({ 
      isOpen: true,
      exam_type:this.props.type,
      exam_title: this.props.title, 
      exam_description: this.props.description, 
      exam_time: this.props.actualTime, 
      time_min: (!this.props.calculate_time)? Math.floor(this.props.actualTime / 60): "",
      exam_start_date: this.props.startDate? new Date(this.props.startDate) : null, 
      exam_end_date: this.props.endDate ? new Date(this.props.endDate) : null, 
      add_exam_dates: this.props.startDate ? true : false, 
      add_exam_time: !this.props.calculate_time
    });
  }
  render() {
    const examTypesTips = {
      normal:
        "The candidate can solve the questions in any order he desires, and the time limit is on the exam as a whole.",
      restricted:
        "The candidate must solve the questions in a specific order, and there's a time limit on every question."
    };
    const modal = (
      <React.Fragment>
        <div className="page-mask" />
        <Animated
          animationIn="slideInUp"
          animationOut="slideOutDown"
          isVisible={true}
          className="add-exam-animated"
        >
          <div className="modal">
            <header>
              <h3>{this.props.editMode? "Edit Exam Metadata" : "Add Exam"}</h3>
            </header>
            <div className="errorContainer">
              {this.state.error ? (
                <Error msg={this.state.error} type="error" />
              ) : (
                ""
              )}
            </div>
            <div className="menu">
              <p>Exam Type</p>
              <Select
                id="type-selector"
                value={{value: this.state.exam_type, label: capitalize(this.state.exam_type) }}
                isClearable={false}
                options={[{value: 'normal', label: 'Normal' }, {value: 'restricted', label: 'Restricted' }]}
                getOptionLabel={(type) => (
                  <div className='select-label-container'>
                  <span className="exam-select-label">{type.label}</span>
                  <Tooltip
                    placement="right"
                    destroyTooltipOnHide={false}
                    trigger='hover'
                    overlay={<div>{examTypesTips[type.value]}</div>}
                  >
                    <i className="material-icons tooltip-icon">help</i>
                  </Tooltip>
                </div>
                )}
                getOptionValue={(type) => type.value}
                classNamePrefix="type-select"
                onChange={this.setExamType}
                />
              </div>
            <div className="formContainer">
              <div className="exam_title">
                <label>Exam Title</label>
                <input
                  onChange={(e) =>
                    this.setState({ exam_title: e.target.value })
                  }
                  value = {this.state.exam_title}
                  type="text"
                />
              </div>

              <div className="exam_description">
                <label>Exam Description</label>
                <textarea
                  type="text"
                  onChange={(e) =>
                    this.setState({ exam_description: e.target.value })
                  }
                  value = {this.state.exam_description}
                />
              </div>

              <div className="checkbox defaultCheck">
                <label className="checboxLabel">
                  Add Exam Start and End Dates
                </label>
                <input
                  type="checkbox"
                  id="check"
                  onClick={this.handleExamDates}
                  checked= {this.state.add_exam_dates ? true : false}
                />
              </div>
              <form className={this.state.add_exam_dates ? "" : "disabledDates"} ref={this.dates}>
                <div className="start_date">
                  <label>Exam Start Date</label>
                  <DateTimePicker
                    className="picker"
                    value={this.state.exam_start_date}
                    format="dd-MM-y h:mm a"
                    locale="en"
                    minDate={new Date()}
                    monthPlaceholder="mm"
                    yearPlaceholder="yy"
                    dayPlaceholder="dd"
                    onChange={(value) =>
                      this.setState({ exam_start_date: value })
                    }
                  />
                </div>
                <div className="end_date">
                  <label>Exam End Date (optional)</label>
                  <DateTimePicker
                    className="picker"
                    value={this.state.exam_end_date}
                    format="dd-MM-y h:mm a"
                    locale="en"
                    minDate={this.state.exam_start_date}
                    monthPlaceholder="mm"
                    yearPlaceholder="yy"
                    dayPlaceholder="dd"
                    onChange={(value) =>
                      this.setState({ exam_end_date: value })
                    }
                  />
                </div>
              </form>
             {
              this.state.exam_type == 'normal' ?
              (
                <React.Fragment>
                  <div className="checkbox defaultCheck">
                    <label className="checboxLabel">
                      Add Custom Exam Time (Calculated by Default)
                    </label>
                    <input
                      type="checkbox"
                      id="check"
                      onChange={this.handleExamTime}
                      checked={this.state.add_exam_time? true : false}
                    />
                  </div>
                      {/* <br /><br/> */}
                      
                  <form className={this.state.add_exam_time ? "time" : "time disabledTime"} ref={this.time}>
                    <div className="exam_time">
                      <label>Exam Time in Minutes</label>
                      <input
                        type="number"
                        min="0"
                        value={this.state.time_min}
                        onChange={this.handleTimeMinutes}
                      />
                          {this.props.calculated_time ? <div className="calculated-time">Calculated Time: {Math.floor(this.props.calculated_time / 60) + " min, " + (this.props.calculated_time - (Math.floor(this.props.calculated_time / 60) * 60)) + " sec"}</div> : ""}
                    </div>
                  </form>
                </React.Fragment>
              ) : 
              null
            }

              <div className="footerActions">
                <button
                  onClick={() =>{
                    this.setState({
                      isOpen: false,
                      exam_description: "",
                      exam_title: "",
                      exam_start_date: null,
                      exam_end_date: null,
                      exam_time: "",
                      error: "",
                    })
                  }
                  }
                >
                  Cancel
                </button>
                <button onClick={this.handleSubmit} className="addExamButton">
                  <span>{this.props.editMode? "Update Exam" : "Add Exam"}</span>
                </button>
              </div>
            </div>
          </div>
        </Animated>
      </React.Fragment>
    );

    const addBtn =(
      <div
      className="examBtnAdd"
      onClick={() => {
        this.setState({ isOpen: true });
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
      </svg>

    </div>
    );

    const editBtn = (
      <div>
      <header>
      <button className= {(this.props.isCreator && !this.props.hasAttempts) ? 'edit-btn':'edit-btn disable'} onClick= {this.handleEdit} >
      Edit Exam Metadata
      </button>
      </header>

      </div>
    );
    
    const BtnRender = this.props.editMode ? editBtn : addBtn;
    const modalRender = this.state.isOpen ? modal : "";

    return (
      <div>
        {BtnRender}
        {modalRender}
      </div>
    );
  }
}

export default withRouter(addExam)