import React, { Component } from "react";
import { withAlert } from "react-alert";
import SidePanel from "./side-panel";
import { API } from "../../api";
import { positions, Provider as AlertProvider } from 'react-alert'
import "../../scss/exam/exam.scss";
import "../../scss/addQ/modal.scss";
import FullPageLoader from "../FullPageLoader/FullPageLoader"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { disableInspection, isValidURL } from "../../utils";
/*
  this class shows the exam that show to the candidate to solve
  it contains:
  Question: shows all questions of the exam
  SidePanel: the panel in the left that shows questions title and the submit button
  modal: when candidate click on submit then a modal with confirmation message shown
*/
class RestrictedExam extends Component {
  constructor(props) {
    super(props);
    this.end_date = 0
    this.state = {
      loading: true,
      questionTime: 0, //Minutes
      is_last: false,
      disableAnswer: false,
      question: {},
      question_number: 1,
      questions_count: null,
      isLightboxOpen: false,
      answer: {
        mcq: [],
        essay: null,
        true_false: null,
        next: "true",
        question_id: null,
      }
    };
    this.hasAnswer = false;
  }

  essayChecked = (e) => {
    this.state.answer.essay = e.target.value

    this.hasAnswer = this.state.answer.essay.length !== 0
  }
  mcqchecked = (e, choice) => {
    let answer = Object.assign({}, this.state.answer);
    if (e.target.checked === true) {
      if (this.state.question.has_single_answer) {
        answer.mcq = []
      }
      answer.mcq.push({choice_id: choice});
    } else if(!this.state.question.has_single_answer){
      for (let i = 0; i < answer.mcq.length; i++) {
        if (answer.mcq[i].choice_id === choice) {
          answer.mcq.splice(i, 1);
          break;
        }
      }
    }
    this.setState({answer: answer});
    this.hasAnswer = answer.length !== 0
  }

  trueFalseCheck = (e, choice) => {
    let answer = Object.assign({}, this.state.answer);
    if (e.target.checked === true) {
      answer.true_false = choice;
    }
    this.setState({answer: answer})
    this.hasAnswer = true;
  }

  answerCheck = (e, choice) => {
    if (this.state.question.question_type === 'mcq') {
      this.mcqchecked(e, choice);
    } else {
      this.trueFalseCheck(e, choice);
    }
  }
  goBack = () => {
    this.props.history.push("/");
  }
  getAnswerValue = (choice_id) => {
    if (this.state.question.question_type == 'mcq') {
      for (let i = 0; i < this.state.answer.mcq.length; i++) {
        if (this.state.answer.mcq[i].choice_id == choice_id) return true;
      }
      return false;
    } else {
      return this.state.answer.true_false == choice_id;
    }
  }
  /* submit the answer to the server when the candidate submit the exam or the time ended */
  handleSubmission = (isNext, goBack = false) => {
    if (isNext == 'true') {this.setState({loading: true});}
    let answer = this.state.answer;
    answer.next = isNext;
    answer.question_id = this.state.question.id;
    API.put(`attempts/${this.props.match.params.id}`, answer)
      .then((res) => {
        if (this.state.is_last) {
          localStorage.removeItem(
            "visitedGuide" + this.props.match.params.id.toString()
          );
          this.setState({
            loading: false
          })
          this.props.history.push("/me/attempt/submitted");
          this.props.alert.removeAll();
          return;
        }
        if (isNext == 'false') {
          this.setState({
            disableAnswer: true,
            loading: false
          })
          if (goBack) {
            this.props.history.push("/me/attempt/submitted");
            return
          }
          this.props.alert.removeAll();
          this.props.alert.success(`Answer submitted successfully!`, {
            position: positions.TOP_RIGHT
          });

          return;
        }
        this.hasAnswer = false;
        this.setState({
          question: res.data.question,
          questionTime: Math.ceil(res.data.remaining_time),
          loading: false,
          disableAnswer: false,
          is_last: res.data.is_last,
          question_number: res.data.question_num,
          questions_count: res.data.questions_count,
          answer: {
            mcq: [],
            essay: null,
            true_false: {},
            next: "true",
            question_id: null,
          }
        });
      })
      .catch((err) => {
        this.setState({
          loading: false
        })
        this.props.alert.removeAll();
        this.props.alert.show(
          'your question failed to submit try again'
        );
      });
  };
  componentDidMount() {
    // this.interval = setInterval(() => this.tick(), 1000);

    API.get(`attempts/${this.props.match.params.id}?exam_type=restricted`)
      .then((res) => {
        // if last question was submitted
        if (res.data.question == undefined){
          this.props.alert.removeAll();
          this.props.history.push("/me/attempt/submitted");
          return
        }
        this.setState({
          question: res.data.question,
          questionTime: Math.ceil(res.data.remaining_time),
          disableAnswer: false,
          loading: false,
          is_last: res.data.is_last,
          question_number: res.data.question_num,
          questions_count: res.data.questions_count,
        });
        this.end_date = res.data.end_date
        this.hasAnswer = false;
      })
      .catch((err) => {
        if (err.response.status === 405) {
          this.props.alert.removeAll();
          this.props.alert.show("You have already finished the exam");
          this.props.history.push("/");
        } else if (err.response.status === 422) {
          this.props.history.push("/");
        } else {
          alert("Error: " + err);
        }

      });
    disableInspection();
  }

  render() {
    if (this.state.loading) {
      return <FullPageLoader />
    }
    let title = [];
    let answers = null;
    title = this.state.question.title.split("\n").map((i) => {
      let splittedQuestion = i.split(" ");
        let result = "";
        splittedQuestion.forEach(function (segment) {
          let word = "";
          if (!isValidURL(segment)) {
            let splittedWord = segment.split("");
            splittedWord.forEach(function (char) {
              word += `<span class="me-2">${char}</span>`
            })
          }
          result += isValidURL(segment)
            ? `<a href=${segment} target="_blank">${segment}</a>`
            : `${word} `;
      });
        return <div dangerouslySetInnerHTML={{__html: result}}/>;
    });
    let answersList = this.state.question.choices_attributes.map((choice, j) => {
      return this.getAnswerValue(choice.id)
    })
    if (this.state.question.choices_attributes.length === 0 && this.state.question.question_type === "essay") {
      answers = (
        <textarea
          onChange={(e) => {
            if (!this.state.disableAnswer)
              this.essayChecked(e)
            }
          }
          disabled={this.state.disableAnswer}
        >{this.state.answer.essay}</textarea>
      )
    } else {
      if(this.state.question.question_type === "true_false"){
        this.state.question.choices_attributes = [{choice_text: "True", id:1, question_id: this.state.question.id},{choice_text: "False", id:0, question_id: this.state.question.sid}]
        this.state.question.has_single_answer = true;
      }
        answers = this.state.question.choices_attributes.map((choice, j) => {
          return (
            <div className="question-choice" key={j} ref={this.myRef}>
              <input
                onClick={(e) => {
                  if(!this.state.disableAnswer){
                    this.answerCheck(e, choice.id)
                  }
                }
                }
                name={this.state.question.has_single_answer ? "questionOption" : ""}
                type={this.state.question.has_single_answer ? "radio" : "checkbox"}
                id={(this.state.question.has_single_answer ? "radio" : "check") + j + "q"}
                checked= { answersList[j] }
                disabled={this.state.disableAnswer}
              />
              <label for={(this.state.question.has_single_answer ? "radio" : "check") + j + "q"}>
                {choice.choice_text}
              </label>
            </div>
          );
        });
    }
    const question = (
        <div className="exam-question" id={"question"}>
          <div className="question-body">
            <p>{title}</p>
            <form>
              {this.state.question.question_type === "mcq" && !this.state.question.has_single_answer ? (
                <span className="multiple-tip">
                  * You can choose multiple answers
                </span>
              ) : null}
              {answers}
            </form>
          </div>
        <img onClick={() => this.setState({isLightboxOpen: true, selectedQuestionImage: this.state.question.question_image})} src={this.state.question.question_image} className="question-img-lg"/>
        </div>
      );

    let questionTime = this.state.questionTime;
    this.state.questionTime = 0
    return (
      <div className="exam-container">
        <SidePanel
          handleSubmission={this.handleSubmission}
          question={this.state.question_number}
          time={questionTime}
          questionsNum={this.state.questions_count}
          submitBtnText={this.state.is_last ? "Submit" : "Next"}
          examType="restricted"
          goBack={this.goBack}
          end_date={this.end_date}
        />
        <div
          className="question-container"
        >
          {question}
          {this.state.isLightboxOpen && (
            <Lightbox
              mainSrc={this.state.selectedQuestionImage}
              onCloseRequest={() => this.setState({ isLightboxOpen: false, selectedQuestionImage: "" })}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withAlert()(RestrictedExam);
