import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import QuestionCard from "../questions-list/question-card";
import { API } from "../../api";
import AddExam from "../addExam/addExam"
import "../../scss/addExam/singleExam.scss";
import {capitalize} from '../../utils'



class singleExam extends Component {
  constructor(props) {
    super(props);
    let user = JSON.parse(localStorage.userObject);
    this.state = {
      exam_config: null,
      exam_questions: [],
      loading: true,
      id: this.props.location.pathname.split("/")[3],
      current_user: user.first_name + " " + user.last_name,
      editExam: true,
      exam_data: null,

    };
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.replace("/me/exams_list");
    }
    API.get(
      `/exams/${this.state.id}`
    ).then((res) => {
      this.setState({ loading: false, exam_questions: res.data.questions, exam_config: res.data.exam_config, exam_data: res.data.exam_config});
    });
    
  }
  loadChoices = async (id) => {
    let headers = {
      id: this.state.id,
    };
    return API.get(`/questions/${id}`, { headers: headers });
  };
  handleUpdate = (data) => {
    this.setState({exam_config: data});
  }
  handleAddQuestion = () => {
    this.props.history.push({
      pathname: "/me/my_questions",
      state: this.state,
    });
  };
  render() {
    let questions = [];
    if(this.state.exam_questions && this.state.exam_questions.length){
      questions = this.state.exam_questions.map((question) => (
        <QuestionCard
          id={question.id}
          content={question.title}
          dont_show_options={true}
          difficulty={question.difficulty}
          time={question.time}
          choices={this.loadChoices}
          tags={[]}
          created_at={question.created_at}
          type={question.question_type}
          author={question.author}
          question_image={question.question_image}
          current_user={this.state.current_user}
        />
      ));

    }
    if (this.state.loading == true) {
      return null;
    }
    return (
      <div>
        <div className="metadata">
          <header className = "head">
            <h1>Exam information</h1>

            <AddExam 
            isCreator= {this.state.exam_config.creator_type === "User" && (this.state.exam_config.creator_id === JSON.parse(localStorage.userObject).id) ? true:false} 
            editMode= {this.state.editExam}
            id = {this.state.exam_config.id} 
            interviewerID = {this.state.exam_config.creator_type === "User" ? this.state.exam_config.creator_id : null}
            type = {this.state.exam_config.exam_type}
            title = {this.state.exam_config.exam_title}
            description = {this.state.exam_config.description}
            startDate = {this.state.exam_config.start_date}
            endDate = {this.state.exam_config.end_date}
            actualTime = {this.state.exam_config.actual_time_in_minutes}
            duration = {this.state.exam_config.calculated_time_in_minutes?`${this.state.exam_config.calculated_time_in_minutes} Minutes`:null}
            handleUpdate = {this.handleUpdate}
            calculate_time = {this.state.exam_config.calculate_time}
            hasAttempts = {this.state.exam_config.has_attempts}
            calculated_time = {this.state.exam_config.calculated_time_in_minutes}
              />
          </header>
 
          <section>
            <div className="flex title">
              <h4>Title:</h4>
              <span className="input">{this.state.exam_config.exam_title}</span>
            </div>

            <div className="flex">
              <h4>Type:</h4>
              <span className="input">{capitalize(this.state.exam_config.exam_type)}</span>
            </div>
          
              <div className="flex">
                <h4>Description</h4>
                <div className="input desc">
                  {this.state.exam_config.description?this.state.exam_config.description:'-'}
                </div>
              </div>
           
           
              <div className=" flex start">
                <h4>Start Date</h4>
                <span className="input">{this.state.exam_config.start_date?new Date(this.state.exam_config.start_date).toDateString()+' '+new Date(this.state.exam_config.start_date).toLocaleTimeString():'Not Added'}</span>
              </div>
          
            
              <div className="flex end">
                <h4>End Date</h4>
                <span className="input">{this.state.exam_config.end_date?new Date(this.state.exam_config.end_date).toDateString()+' '+new Date(this.state.exam_config.end_date).toLocaleTimeString():'Not Added'}</span>
              </div>
            
              {
                this.state.exam_config.exam_type === "normal" &&
                (
                  <div className=" flex duration">
                    <h4>Actual Duration</h4>
                    <span className="input">
                      {((this.state.exam_config.actual_time_in_minutes && !this.state.exam_config.calculate_time) && this.state.loading===false)
                        ? `${Math.floor(this.state.exam_config.actual_time_in_minutes / 60)} minutes`
                        : 'Not Enabled'}
                    </span>
                  </div>
                )
              }
              
            

            
              <div className=" flex duration">
                <h4>Calculated Duration</h4>
                <span className="input">
                  {(this.state.exam_config.calculated_time_in_minutes && this.state.exam_config.calculate_time) && this.state.loading===false
                    ? `${Math.floor(this.state.exam_config.calculated_time_in_minutes / 60)} min, ${this.state.exam_config.calculated_time_in_minutes - Math.floor(this.state.exam_config.calculated_time_in_minutes / 60) * 60} sec`
                    : 'Not Enabled'}
                </span>
              </div>
            
          </section>
        </div>
        <div className="questions">
          <header className="qhead">
            <h1>Exam Questions</h1>
            <div className="actions">
              <button className={!(this.state.current_user===this.state.exam_config.exam_creator) || this.state.exam_config.has_attempts ?'disable':''} onClick={this.handleAddQuestion}>
                Manage Exam Questions
              </button>
            </div>
          </header>
          <div className="exam_questions">
            {questions.length === 0 && this.state.loading === false ? (
              <div className="emptyState">
                <div className="text">
                  <h1>This exam has no questions</h1>
                  <p>
                    Add questions to this exam by clicking on the Edit Exam
                    Questions button
                  </p>
                  <p>
                    Back to <Link to="/me/exams_list">exams page</Link>
                  </p>
                </div>
              </div>
            ) : (
              questions
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(singleExam);