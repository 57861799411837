import {API} from '../api';
import {SET_CURRENT_USER} from './types';

export const setCurrentUser = (token, uid, client) => {
  return {
    type: SET_CURRENT_USER,
    token,
    uid,
    client
  };
}
 export function createQuestion(question){
      return API.post(`questions`, question);
  }
  export function getTagsList(){
      return API.get(`categories`);
    }

