import React, {Component} from "react";
import "../scss/error.scss";

class Error extends Component{

	render(){
		return(
			<div className={this.props.type + " msg"}> 
		        <span>{this.props.msg}</span>
		  </div>
		);
	}
}
export default Error;