import React, {Component} from 'react';
import '../../scss/exam/exam.scss';

class SidePanel extends Component{
  constructor(props){
    super(props);
    let time = String(this.props.time).secsToHHMMSS();
    this.state = {
      time: {
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds
      },
      modalOpen: false,
      finished: false
    }
  }
  /* update counter every second and auto submit the exam if the time ended */
  tick(){
    let time = Object.assign({}, this.state.time);

    //Time mechanism
    if(time.seconds === "00" && time.minutes === "00" && time.hours === "00"){
      if (this.props.examType === "restricted" && this.props.end_date && this.props.end_date <= new Date().getTime() / 1000) {
        // exam end date happened
        this.props.handleSubmission("false", true)
      }

      return;
    }
    if(time.minutes === "00" && time.hours !== "00"){
      time.minutes = "60";
      time.hours -= 1;
    }
    if(time.seconds === "00"){
      time.seconds = "60";
      time.minutes -= 1;
    }

    time.seconds -= 1;
    time.minutes = parseInt(time.minutes);
    time.hours   = parseInt(time.hours);

    //Formatting time
    if(parseInt(time.seconds) < 10){
      time.seconds = "0" + time.seconds;
    }
    if(parseInt(time.minutes) < 10){
      time.minutes = "0" + time.minutes;
    }
    if(parseInt(time.hours) < 10){
      time.hours = "0" + time.hours;
    }
    this.setState({ time });

    //Time mechanism
    if (time.seconds === "00" && time.minutes === "00" && time.hours === "00") {

      if (this.state.finished === false) {
        this.setState({ finished: true })
        this.props.handleSubmission(this.props.examType === "restricted" ? "false" : true);
      }
      
    }
    if (this.props.examType === "restricted" && this.props.end_date && this.props.end_date <= new Date().getTime() / 1000) {
      // exam end date happened
      this.props.handleSubmission("false", true)
    }
  }
  /*
  * update answer in the server every 10 seconds if there is any change
  */
  updateAnswer = (isClicked) =>  {
    if (this.props.examType == "normal"){
      this.props.handleDraftSubmission(isClicked)
    }
  }
  backClick = () => {
    if (this.props.examType === "restricted") {
      this.setState({modalOpen: true});
    } else {
      this.props.goBack();
    }
    
  }
  componentDidMount(){
    //Start timer
    this.interval = setInterval(() => this.tick(), 1000);
    // start auto update the answer every 10 seconds to the server
    this.draftInterval = setInterval(() => {this.updateAnswer(false);}, 10000);
  }

  componentWillUnmount(){
    clearInterval(this.draftInterval)
    clearInterval(this.interval)
  }

  render(){
    let modal;
    if (this.state.modalOpen) {
      modal = (
        <div className="popup">
          <React.Fragment>
            <div className="page-mask" >
         
              <div className="modal" isVisible={true}>
                <header>
                  <h3>Are you sure you want to go back?</h3>
                </header>
                <div className="errorContainer alert" >
                 your answer to this question will not be saved
                </div>
                <div className="formContainer">

                  <form>
                    <div className="footer">
                      <div style={{ marginRight: "10px" }}>
                        <button
                        className="popupButton"
                         
                          onClick={() => this.props.goBack()}
                        >Yes </button>
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        <button
                        className="popupButton"
                          
                          onClick={() => this.setState({ modalOpen: false })}
                        >Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      );
    }
    const time = this.state.time;
    const listItems = this.props.examType === "restricted" ? null : this.props.questions.map((item,i)=>{
      return <li id={this.props.question === i+1 ? "current" : ""} className={this.props.status[i].isDone?"done":""} ><a href={"#question"+(i+1)}>{(i+1)+"."+ (item.title.length >= 18 ? item.title.substring(0,18)+".." : item.title)}</a></li>
    });
    return (
      <div className="exam-side-panel">
        <div className="side-panel-timer">
          <h1>
            {time.hours}:{time.minutes}:{time.seconds}
          </h1>
          <span>
            {" "}
            Question {this.props.question} out of {this.props.questionsNum}{" "}
          </span>
        </div>
        {this.props.examType === "restricted" ? null : (
          <div className="save-question-list">
            <button className="save-answers-button" onClick={() => this.updateAnswer(true)}>Save Answers</button>
            <div className="side-panel-list">
              <ul>{listItems}</ul>
            </div>
          </div>
        )}
        <button className="submit-exam" onClick={this.props.examType === "restricted" ? () => this.props.handleSubmission("true") : this.props.handleEnsuringSubmission}>{this.props.submitBtnText}</button>
        <div className="actions">
          <a href="#" onClick={this.backClick}>
            <span className="d-lg">Go back to applications list</span>
            <span className="d-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 55.753 55.753"
              >
                <path
                  d="M12.745,23.915c0.283-0.282,0.59-0.52,0.913-0.727L35.266,1.581c2.108-2.107,5.528-2.108,7.637,0.001
                  c2.109,2.108,2.109,5.527,0,7.637L24.294,27.828l18.705,18.706c2.109,2.108,2.109,5.526,0,7.637
                  c-1.055,1.056-2.438,1.582-3.818,1.582s-2.764-0.526-3.818-1.582L13.658,32.464c-0.323-0.207-0.632-0.445-0.913-0.727
                  c-1.078-1.078-1.598-2.498-1.572-3.911C11.147,26.413,11.667,24.994,12.745,23.915z"
                />
              </svg>
              Back
            </span>
          </a>
        </div>
        {modal}
      </div>
    );
  }
}

export default SidePanel;

//Helper method for converting minutes to time in HH:MM:SS
String.prototype.secsToHHMMSS = function () {
    let hours = Math.floor(parseInt(this) / 3600);
    let minutes = Math.floor((parseInt(this) / 60) % 60);
    let seconds = parseInt(this) % 60;

    // Appends 0 when unit is less than 10
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return {
      hours: String(hours),
      minutes: String(minutes),
      seconds: String(seconds)
    }
}
