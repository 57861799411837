import {SET_CURRENT_USER} from '../actions/types';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  isAuthenticated: false,
  token: '',
  client: '',
  uid: ''
};

//User state reducer
export default (state = initialState, action = {}) => {
  switch(action.type) {
    case SET_CURRENT_USER:
      //If token exists set 'isAuthenticated' as true
      let tokenExists = action.token == '' ? false : true;
      //Return new state object
      return {
        isAuthenticated: tokenExists,
        token: action.token,
        uid: action.uid,
        client:action.client
      };
    default: return state;
  }
}