import React, { Component } from "react";
import Add from "../addQ/Add";
import Filter from "../questions-list/filter";
import QuestionCard from "../questions-list/question-card";
import "../../scss/myQuestions/list.scss";
import "../../scss/addQ/Add.scss";
import { API } from "../../api";
import Modal from "../addQ/modal";
import emptyStateSVG from "../../images/emptyState.svg";
import ImportQuestions from "../addQ/importQuestion";
import { withAlert } from "react-alert";
import ImportStatus from "../addQ/ImportStatus";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Loader from "react-loader-spinner";

class Questions extends Component {
  constructor(props) {
    super(props);
    let user = JSON.parse(localStorage.userObject);

    this.state = {
      question_error:'',
      questions_to_be_added_in_edit_mode: [],
      questions_to_be_deleted_in_edit_mode:[],
      applyLoading:false,
      questions_exist_in_exam: [],
      in_exam_edit_mode: false, //toggled when editing exam questions
      addButtonClicked: false,
      current_user: user.first_name + " " + user.last_name,
      inEditMode: false,
      filterMode: false,
      question_edit: {},
      per_page: 9,
      current_page: 1,
      filter_page: 1,
      total: 0,
      questions_loaded: false,
      empty_choices: [
        { answer_text: "", is_correct: false },
        { answer_text: "", is_correct: false },
        { answer_text: "", is_correct: false },
      ],
      error: "",
      tags: [],
      showQuestionOptions: false, //the boolean that controls showing import excel file or adding a question manually
      importStatusProps: {
        added: 0,
        notAdded: 0,
        downloadLink: "",
      },
      isImportStatusOpen: false,
      isLightboxOpen: false,
      selectedQuestionImage: "",
    };
    this.loadChoices = this.loadChoices.bind(this);
    this.httpRequest = this.httpRequest.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    if (JSON.parse(localStorage.userObject).type === "Candidate") {
      this.props.history.replace("/me/applications");
    }
    this.loadQuestions = this.loadQuestions.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
    this.exitEditModal = this.exitEditModal.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.showImportStatus = this.showImportStatus.bind(this);
    this.hideImportStatus = this.hideImportStatus.bind(this);
    this.handleImageExpand = this.handleImageExpand.bind(this);
  }

  handleImageExpand(image) {
    this.setState({isLightboxOpen: true, selectedQuestionImage: image})
  }
  
  addQuestionsEditMode = (question_id) => {
    let added_question = this.state.questions.find(
      (question) => question.id === question_id
    ); //the green plus sign adding questions ...
    if(this.state.questions_exist_in_exam.includes(added_question)){
      let arr=this.state.questions_to_be_deleted_in_edit_mode.filter((q)=>q.id!==added_question.id)
      this.setState({questions_to_be_deleted_in_edit_mode:arr})
    }
    else{
      this.setState({questions_to_be_added_in_edit_mode:[...this.state.questions_to_be_added_in_edit_mode,added_question]})
    }
  
  };
  removeQuestionsEditMode=(question_id)=>{
    let removed_question = this.state.questions.find(
      (question) => question.id === question_id
    );
    //check if its already in the exam or i just pushed it and changed my mind
    if(this.state.questions_exist_in_exam.includes(removed_question)){
      /// already in exam questions (registered in database) ...
      this.setState({questions_to_be_deleted_in_edit_mode:[...this.state.questions_to_be_deleted_in_edit_mode,removed_question]})
    }
    else{
      //i added it right now and would like to remove it again
      let new_questions_array=this.state.questions_to_be_added_in_edit_mode.filter((q)=>q.id!==removed_question.id)
      this.setState({questions_to_be_added_in_edit_mode:new_questions_array})
    }
  }
    exit_exam_edit_mode=()=>{
    this.setState({in_exam_edit_mode:false,questions_to_be_added_in_edit_mode:[],questions_exist_in_exam:[]},()=>{
      this.props.history.goBack()
    })

  }
  handleAddingExamQuestions=()=>{
    let added_questions_id=this.state.questions_to_be_added_in_edit_mode.map((q)=>q.id)
    let deleted_questions_id=this.state.questions_to_be_deleted_in_edit_mode.map((q)=>q.id)
    let exam_id=this.props.location.state.exam_data.id
    this.setState({applyLoading:true})
    /// api call here
    let data={
      added_questions:added_questions_id,
      deleted_questions:deleted_questions_id,
    }
   API.post(`/exams/${exam_id}/manage`,data).then((res)=>{
    this.setState({applyLoading:false,questions_to_be_added_in_edit_mode:[],questions_to_be_deleted_in_edit_mode:[],in_exam_edit_mode:false,questions_exist_in_exam:[]},()=>{
      this.props.history.goBack();

    })
   }).catch((err)=>{

   })


  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  exitEditModal() {
    this.setState({ inEditMode: false });
  }
  async loadChoices(id) {
    let headers = {
      id: this.state.id,
    };
    return API.get(`/questions/${id}`, { headers: headers });
  }

  async httpRequest(pageNumber, filter = null) {
    let questions;
    let order_by_existing=false;
    let exam_id;
    let existing_flag;
    
    if(this.props.location.state !== undefined){
      order_by_existing=true;
      exam_id=this.props.location.state.exam_data.id;
      existing_flag=`&order_by_existing=${order_by_existing}&exam_id=${exam_id}`
    }
    if (filter === null) {
     
      await API.get(
      `/questions?page=${pageNumber}&per_page=${this.state.per_page}${existing_flag}`
      ).then((res) => {
        questions = res.data.questions;
        questions.forEach((question) => {
          question.tags = question.categories.map((category) => {
            return { text: category.title, id: category.id };
          });
        });
        this.setState({
          questions: questions,
          questions_loaded: true,
          total: res.data.total_questions,
          current_page: pageNumber,
          last_page: Math.ceil(res.data.total_questions / this.state.per_page),
        });
      });
    } else {
      API.get(filter + `&page=${pageNumber}&per_page=${this.state.per_page}${existing_flag}`)
        .then((res) => {
          this.setState({ filter, current_page: pageNumber });
          this.loadQuestions(res.data.questions, res.data.total_questions);
        })
        .catch((err) => {
          this.setState({ error: err });
        });
    }
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      this.setState({ in_exam_edit_mode: true });
    }
    this.httpRequest(1).then(() => {
      if (!this.state.in_exam_edit_mode) return;
      var common_questions = this.state.questions.filter((n) =>
        this.props.location.state.exam_questions.some((n2) => n.id == n2.id)
      ); //getting common questions
      this.setState({ questions_exist_in_exam: common_questions });
    });
  }

  showImportStatus(added, notAdded, downloadLink) {
    // showing simple alert if all questions imported successfully
    if (notAdded === 0) {
      this.props.alert.success(
        `${added} Question${added > 1 ? "s" : ""} added successfully!`
      );
      // reloading questions
      this.setState({ questions_loaded: false }, () => {
        this.httpRequest(1);
      });
    } else {
      // shows a modal if any of the questions was invalid then reloads questions.
      this.setState(
        {
          importStatusProps: { added, notAdded, downloadLink },
          questions_loaded: false,
          isImportStatusOpen: true,
        },
        () => {
          this.httpRequest(1);
        }
      );
    }
  }

  hideImportStatus() {
    this.setState({ isImportStatusOpen: false });
  }

  editQuestion(question) {
    this.loadChoices(question.id).then((res) => {
      if (res.data.question_type === "mcq") {
        question.choices = res.data.choices_attributes.map((choice, k) => {
          return {
            id: choice.id,
            choice_text: choice.choice_text,
            is_correct: choice.is_correct,
          };
        });
      } else if (res.data.question_type === "true_false") {
        let correct = res.data.t_f_answer ? true : false;
        question.choices = [
          { id: 1, choice_text: "True", is_correct: correct },
          { id: 0, choice_text: "False", is_correct: !correct },
        ].map((choice, k) => {
          return {
            id: choice.id,
            choice_text: choice.choice_text,
            is_correct: choice.is_correct,
          };
        });
      }
      this.setState({ question_edit: question, inEditMode: true });
    });
  }
  updateQuestion(id, updatedQuestion) {
    this.setState({ inEditMode: false });
    this.httpRequest(this.state.current_page);
  }
  addQuestion(question) {
    let new_total = this.state.total + 1;
    this.setState(
      {
        total: new_total,
        last_page: Math.ceil(new_total / this.state.per_page),
      },
      () => {
        this.httpRequest(1).then(() => {
          this.setState({ current_page: 1 });
        });
      }
    );
  }
  deleteQuestion(id) {
    if (window.confirm("Are you sure you want to delete this question?")) {
      API.delete(`/questions/${id}`)
        .then((res) => {
          this.httpRequest(this.state.current_page);
        })
        .catch((err) => {});
    }
  }
  //Used for search and filter
  loadQuestions(questions, total) {
    questions.forEach((question) => {
      question.tags = question.categories.map((category) => {
        return { text: category.title, id: category.id };
      });
    });
    this.setState(
      {
        last_page: Math.ceil(total / this.state.per_page),
        filterMode: true,
        questions: questions,
        total: total,
      },
      () => {
        if (!this.state.in_exam_edit_mode) return;
        var common_questions = this.state.questions.filter((n) =>
          this.props.location.state.exam_questions.some((n2) => n.id == n2.id)
        ); //getting common questions
        this.setState({ questions_exist_in_exam: common_questions });
      }
    );
  }

  refreshQuestions(page) {
    this.setState({ current_page: page });
    if (this.state.filter !== undefined) {
      this.httpRequest(page, this.state.filter).then(() => {
        if (!this.state.in_exam_edit_mode) return;
        var common_questions = this.state.questions.filter((n) =>
          this.props.location.state.exam_questions.some((n2) => n.id == n2.id)
        ); //getting common questions
        this.setState({ questions_exist_in_exam: common_questions });
      });
    } else {
      this.httpRequest(page).then(() => {
        if (!this.state.in_exam_edit_mode) return;
        var common_questions = this.state.questions.filter((n) =>
          this.props.location.state.exam_questions.some((n2) => n.id == n2.id)
        ); //getting common questions
        this.setState({ questions_exist_in_exam: common_questions });
      });
    }
  }
  //more of a css function just to display the question adding options
  toggleQuestionOptions = () => {
    this.setState({
      showQuestionOptions: !this.state.showQuestionOptions,
      addButtonClicked: !this.state.addButtonClicked,
    });
  };
  render() {
    let cards = [];
    const pageNumbers = [];
    for (let i = 1; i <= this.state.last_page; i++) {
      pageNumbers.push(i);
    }
    const pages = pageNumbers.map((number, i) => {
      let classes = this.state.current_page === i + 1 ? "active" : "";
      let temp;
      if (this.state.total >= 10) {
        if (
          number === this.state.total ||
          (number >= this.state.current_page - 1 &&
            number <= this.state.current_page + 1)
        ) {
          return (temp = (
            <span
              key={number}
              className={classes}
              onClick={(number) => this.refreshQuestions(i + 1)}
            >
              {" "}
              {number}{" "}
            </span>
          ));
        }
      } else {
        return (temp = (
          <span
            key={number}
            className={classes}
            onClick={(number) => this.refreshQuestions(i + 1)}
          >
            {" "}
            {number}{" "}
          </span>
        ));
      }

      return temp;
    });
    if (this.state.questions_loaded === true) {
      if (this.state.questions.length === 0) {
        cards = (
          <div className="emptyState">
            <div className="text">
              <h1>There are no questions here</h1>
              <span>Add a new question by tapping the add icon.</span>
            </div>
            <img alt="avatar" src={emptyStateSVG} />
          </div>
        );
      }
      for (let i = 0; i < this.state.questions.length; i++) {
        if (i === this.state.per_page) {
          break;
        }
        let item = this.state.questions[i];
        if (
          this.state.in_exam_edit_mode &&
          this.state.questions_exist_in_exam.includes(item)
        ) {
          // if the question is common to differenciate between them
          cards.push(
            <QuestionCard
              common_question={true}
              questions_added_edit={this.state.questions_to_be_added_in_edit_mode}
              questions_removed_edit={this.state.questions_to_be_deleted_in_edit_mode}
              remove_q_in_edit={this.removeQuestionsEditMode}
              add_q_in_edit={this.addQuestionsEditMode}
              edit_mode={this.state.in_exam_edit_mode}
              id={item.id}
              content={item.title}
              difficulty={item.difficulty}
              time={item.time}
              choices={this.loadChoices}
              tags={item.tags}
              created_at={item.created_at}
              type={item.question_type}
              author={item.author}
              current_user={this.state.current_user}
              editQuestion={this.editQuestion}
              deleteQuestion={this.deleteQuestion}
              inExam={item.in_exam}
              key={i}
              question_image={item.question_image}
              handleImageExpand={this.handleImageExpand}
              isLightboxOpen={this.state.isLightboxOpen}
            />
          );
        } else {
          cards.push(
            <QuestionCard
              questions_added_edit={this.state.questions_to_be_added_in_edit_mode}
              questions_removed_edit={this.state.questions_to_be_deleted_in_edit_mode}
              add_q_in_edit={this.addQuestionsEditMode}
              remove_q_in_edit={this.removeQuestionsEditMode}
              edit_mode={this.state.in_exam_edit_mode}
              id={item.id}
              content={item.title}
              difficulty={item.difficulty}
              time={item.time}
              choices={this.loadChoices}
              tags={item.tags}
              created_at={item.created_at}
              type={item.question_type}
              author={item.author}
              current_user={this.state.current_user}
              editQuestion={this.editQuestion}
              deleteQuestion={this.deleteQuestion}
              inExam={item.in_exam}
              key={i}
              question_image={item.question_image}
              handleImageExpand={this.handleImageExpand}
              isLightboxOpen={this.state.isLightboxOpen}
            />
          );
        }
      }
    } else {
      //Create a loading cards screen
      for (let x = 0; x < this.state.per_page; x++) {
        cards.push(
          <QuestionCard
            content="Loading"
            difficulty="Loading"
            time="0"
            choices={this.state.empty_choices}
            tags={[]}
            type=""
            author=""
            current_user={this.state.current_user}
            key={x}
          />
        );
      }
    }
    const back_arrows =
      this.state.current_page === 1 ? (
        ""
      ) : (
        <div>
          <svg
            id="rotate"
            onClick={() => this.refreshQuestions(1)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
          </svg>
          <svg
            onClick={
              this.state.current_page === 1
                ? ""
                : () => this.refreshQuestions(this.state.current_page - 1)
            }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
          </svg>
        </div>
      );
    const front_arrows =
      this.state.current_page === this.state.last_page ? (
        ""
      ) : (
        <div>
          <svg
            onClick={
              this.state.current_page === this.state.total
                ? ""
                : () => this.refreshQuestions(this.state.current_page + 1)
            }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
          </svg>
          <svg
            onClick={() => this.refreshQuestions(this.state.last_page)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
          </svg>
        </div>
      );
    //Edit modal
    const modal = (
      <React.Fragment>
        <div className="page-mask" />
        <Modal
          exitEditModal={this.exitEditModal}
          updateQuestion={this.updateQuestion}
          question={this.state.question_edit}
        />
      </React.Fragment>
    );
    const modalRender = this.state.inEditMode ? modal : "";
    const importStatusModal = this.state.isImportStatusOpen ? (
      <ImportStatus
        validQuestions={this.state.importStatusProps.added}
        invalidQuestions={this.state.importStatusProps.notAdded}
        downloadLink={this.state.importStatusProps.downloadLink}
        hideImportStatus={this.hideImportStatus}
      />
    ) : null;
    const questionOptions = (
      <div className="questionOptions">
        <label className="importQuestionsLabel">Import Questions</label>
        <ImportQuestions
          {...this.props}
          showImportStatus={this.showImportStatus}
        />
        <label className="addQuestionLabel">Add a question</label>
        <Add
          addQuestion={(question) => {
            this.addQuestion(question);
          }}
        />
      </div>
    );
    return (
      <div className="wrapper">
        {this.state.in_exam_edit_mode ? (
          <header className="edit_mode">
            <h1>
              Editing Exam Questions for{" "}
              {this.props.location.state.exam_data.exam_title}
            </h1>
            {this.state.questions_to_be_added_in_edit_mode.length>0?
            ( 
             <p>{this.state.questions_to_be_added_in_edit_mode.length} question(s) added</p>
            
            ):null
           }
            {this.state.questions_to_be_deleted_in_edit_mode.length>0?
            ( 
             <p>{this.state.questions_to_be_deleted_in_edit_mode.length} question(s) removed</p>
            
            ):null
           }
            {this.state.questions_to_be_added_in_edit_mode.length>0 || this.state.questions_to_be_deleted_in_edit_mode.length>0?(<button onClick={this.handleAddingExamQuestions}> {this.state.applyLoading ? (
                    <Loader
                      type="ThreeDots"
                      color="#FFFFFF"
                      height={13}
                      width={13}
                    />
                  ) : (
                    <span>Apply</span>
                  )}</button>):<button disabled={true} style={{opacity:0.5,cursor:'unset'}}>Apply</button>}
            <button onClick={this.exit_exam_edit_mode}>Cancel</button>
          </header>
        ) : null}
        {modalRender}
        {importStatusModal}
        <Filter
          httpRequest={this.httpRequest}
          load={this.loadQuestions}
          page={this.state.filterMode === true ? this.state.current_page : ""}
          per_page={this.state.per_page}
        />
        <div className="que-container">{cards}</div>
        {this.state.showQuestionOptions ? questionOptions : ""}
        {this.state.in_exam_edit_mode ? null : (
          <div>
            <div className="btn" onClick={this.toggleQuestionOptions}>
              {" "}
              <div className="addBtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                </svg>
              </div>
            </div>
          </div>
        )}

        {this.state.total !== 0 && (
          <div className="pages">
            {back_arrows}
            {pages}
            {front_arrows}
          </div>
        )}
        {this.state.isLightboxOpen && (
          <Lightbox
            mainSrc={this.state.selectedQuestionImage}
            onCloseRequest={() =>
              this.setState({
                isLightboxOpen: false,
                selectedQuestionImage: "",
              })
            }
          />
        )}
      </div>
    );
  }
}

export default withAlert()(Questions);
