import React, { Component } from "react";
import "../../scss/addQ/choices.scss";
import Error from "../error.js";
import { getTagsList } from "../../actions/questionActions.js";
import "react-autocomplete-input/dist/bundle.css";


class AddChoice extends Component {
  constructor(props) {
    super(props);
    this.count = 0;
    this.tagsText = "";
    this.tagsList = [];
    this.map = new Map();
    this.fillTagsList();
    this.state = {
      elements: [],
      error: this.props.error,
      tags: this.tagsList,
    };
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fillTagsList = this.fillTagsList.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }
  //get tags list from server to for the autocomplete input
  fillTagsList() {
    let arr = [];
    getTagsList().then((res) => {
      arr = res.data;
      for (let i = 0; i < arr.length; i++) {
        this.map.set(arr[i].title, arr[i].id);
        this.tagsList.push(arr[i].title);
      }
    });
  }
  //handles typing a new tag
  handleChange(event) {
    this.tagsText = event;
    this.setState({ error: "" });
  }
  //handles choosing the correct answer
  toggleActive(e) {
    const { elements } = this.state;
    const index = e.currentTarget.id;
    for (let i = 0; i < this.state.elements.length; i++) {
      if (this.state.elements[i].id == index) {
        elements[i].is_correct = !this.state.elements[i].is_correct;
      } else {
        continue;
      }
    }
    this.setState({ elements });
  }
  
  //handles adding new tags and choices
  addItem(event) {
    event.preventDefault();
    let error = "";
    this.tagsText = "";
    this.setState({ error: error });
    let newItem = "";
    if (this.props.type === "choices") {
      newItem = this.newItem.value;
    } else {
      newItem = this.newItem.recentValue;
    }
    newItem = newItem.trim();
    if (newItem !== "") {
      let t = "";
      let arr = this.state.elements;
      this.props.type === "choices" ? (t = "choice") : (t = "tag");
      //check if the new item is already an existing tag or choice
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].answer_text === newItem) {
          error = "the " + t + " you have entered already exists!";
        }
      }

      if (error === "") {
        //user can enter up to 4 choices and 10 tags
        if (
          (this.props.type === "choices" && this.count < 4) ||
          (this.props.type === "tags" && this.count < 10)
        ) {
          let newId =
            this.state.elements.length === 0
              ? 0
              : this.state.elements[this.state.elements.length - 1].id + 1;
          let array = this.state.elements;
          let found = false;
          //check if the new item is in the tags list
          if (this.props.type === "tags") {
            for (let i = 0; i < this.state.tags.length; i++) {
              if (newItem === this.state.tags[i]) {
                found = true;
              }
            }
          }
          // output an error if the tag is not included from the servers's tags list
          if (this.props.type === "tags" && found === false) {
            error = newItem + " is not a valid tag!";
          }
          // add the tag
          else {
            if (this.props.type === "tags") {
              let tagId = this.map.get(newItem);
              array.push({
                answer_text: newItem,
                is_correct: false,
                id: newId,
                tagId: tagId,
              });
            } else {
              array.push({
                answer_text: newItem,
                is_correct: false,
                id: newId,
              });
            }
            this.setState({
              elements: array,
            });
            if (this.props.type === "choices") {
              this.props.choices("choices", this.state.elements);
              this.count++;
            } else {
              this.props.tags("tags", this.state.elements);
              this.count++;
            }
          }
        } else {
          error = "Maximum number of " + this.props.type + " reached!";
        }
      }

      this.setState({ error: error });
      if (this.props.type === "choices") {
        this.newItem.value = "";
      }
      if (this.props.type === "tags") {
        this.newItem.recentValue = "";
      }
    }
  }

  //removes element from tags or choices
  removeItem(e) {
    let error = "";
    this.setState({ error: error });
    const index = parseInt(
      e.currentTarget.id.charAt(e.currentTarget.id.length - 1)
    );
    let array = this.state.elements;
    array.splice(index, 1);
    this.setState({
      elements: array,
    });
    this.setIds();
    if (this.props.type === "choices") {
      this.props.choices("choices", this.state.elements);
      this.count--;
    }
  }

  //resets the ids (ordering) of elements in the array after removing an element
  setIds() {
    const length = this.state.elements.length;
    const array = [];
    const stateArray = this.state.elements;
    for (let i = 0; i < length; i++) {
      const object = stateArray[i];
      object.id = i;
      array.push(object);
    }
    this.setState({ elements: array });
  }

  // #TODO: No need to check if true_false
  componentWillMount() {
    if (this.props.elements !== undefined) {
      this.setState({ elements: this.props.elements });
      this.count = this.props.elements.length;
    }
  }

  render() {
    var items;
    var inputField;
    if (this.props.type === "choices") {
      items = this.state.elements.map((item, i) => (
        <div className="choice-container">
          <svg
            onClick={(e) => {
              this.removeItem(e);
            }}
            id={"svg" + item.id}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-12v-2h12v2z" />
          </svg>
          <div
            className={"choice " + (item.is_correct ? "choice-active" : " ")}
            id={item.id}
            key={i}
            onClick={this.toggleActive.bind(this)}
          >
            {item.answer_text}
          </div>
        </div>
      ));
      inputField = (
        <input
          ref={(input) => (this.newItem = input)}
          name="text"
          placeholder={
            this.props.type === "choices" ? "Add a choice" : "Add a tag"
          }
        />
      );
    }

    return (
      <div className="items-container">
        {this.state.error === "" ? (
          ""
        ) : (
          <Error msg={this.state.error} type="error" />
        )}
        <form className="items-form" id="inline-form">
          {inputField}

          <button
            name="addButton"
            onClick={(event) => {
              this.addItem(event);
            }}
          ></button>
        </form>

        <div className="choices">{items}</div>
      </div>
    );
  }
}
export default AddChoice;
