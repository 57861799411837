import React, { Component } from "react";
import {withRouter} from 'react-router';
import "../../scss/show.scss";
import SVG from "../../svg.js";
import InputSection, { SelectMenu } from "../FormElements.js";
import {connect} from 'react-redux';
import Error from "../error";
import validate from '../form_validations.js';
import {API} from '../../api';
import {Animated} from "react-animated-css";
import PropTypes from 'prop-types'
import {logout} from '../../actions/authActions';

export class ProfileEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
      user:{
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        mobile_number: '',
      },
      changed: false,
      err: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm   = this.submitForm.bind(this);
    this.positionSelect = this.positionSelect.bind(this);
  }
  handleChange(event, field){
    //Make a copy of user object
    let copy = this.state.user;
    //Add value from text field to corresponding key
    copy[field] = event.target.value;
    this.setState({user: copy});
  }

  submitForm(){
    //Make a copy of user object
    let user = this.state.user;
    let errors = [];

    if(user.first_name !== ''){
      if(!validate.max_length(user.first_name, 20)){
        errors.push('Firstname length should not exceed 20 characters.');
      }
      if(!validate.letters_only(user.first_name)){
        errors.push('Firstname cannot contain numbers or special characters');
      }
    }else{
      errors.push('Firstname cannot be blank');
    }
    if(user.last_name !== ''){
      if(!validate.letters_only(user.last_name)){
        errors.push('Lastname cannot contain numbers or special characters');
      }
    }else{
      errors.push('Lastname cannot be blank');
    }
    if(user.password !== '' && !validate.max_length(user.password, 50)){
      errors.push('Password length should not exceed 50 characters.');
    }
    if(user.last_name !== '' && !validate.max_length(user.last_name, 20)){
      errors.push('Lastname length should not exceed 20 characters');
    }
    if(!validate.equal(user.password, user.password_confirmation)){
      errors.push('Password & Password confirmation do not match.');
    }
    if(user.mobile_number !== '' && user.mobile_number !== null){
      if(!validate.number(user.mobile_number) || !validate.max_length(user.mobile_number, 25)){
        errors.push('Mobile number is invalid');
      }
    }
    if(user.password !== '' && !validate.password(user.password)){
      errors.push('Password must atleast be 7 characters including a number and a letter. ');
    }
    this.setState({ err: errors });
    if(errors.length > 0){
      let error_format = "";
      errors.forEach((error) => {
        error_format += "- " + error + '\n';
      });
      alert(error_format);
    }else{
      //Search for changes
      if(this.state.changed === true || user.first_name !== this.props.first_name || user.last_name !== this.props.last_name || user.mobile_number !== this.props.mobile_number || user.password !== ''){
        this.setState({ changed: true });
      }else{
        this.props.handleShow();
        return;
      }

      let password_changed = false;

      let user_data = new FormData();
      for(let key in this.state.user){
        if(user[key] !== this.props[key]){
          if(key !== 'password' && key !== 'password_confirmation'){
            user_data.append(key, this.state.user[key]);
          }else{
            if(user['password'] !== ''){
              password_changed = true;
              user_data.append(key, this.state.user[key]);
            }
          }
        }
      }

      API.put('auth/', user_data).then((res) => {
        alert("Done!");
        localStorage.setItem('userObject', JSON.stringify(res.data.data));
        this.props.updateUser();
        this.props.updateNavigation(res.data.data.first_name, res.data.data.avatar.url);
        if(password_changed === false){
        }else{
          //Logout
          //Redirect to login
          this.props.logout().then((res) => {
            this.props.history.push('/');
          }).catch((err) => {
            alert("An error has occured");
            this.props.history.push('/');
          });
        }
      }).catch((err) => {
        alert("Error: " + err);
      });
    }
  }

  imageUpload(files){
    let reader = new FileReader();
    let file   = files[0];
    let copy = this.state.user;
    if(!file.type.match("image.*")){
      alert("This file is not valid");
      return;
    }
    copy['avatar'] = file;
    // Use FileReader to display a preview & store image in state once loaded
    reader.onloadend = () => {
        this.setState(copy);
        // Set state for preview
        this.setState({ image_preview_url: reader.result });
        this.setState({ changed: true });
    }

    reader.readAsDataURL(file);
  }

  triggerHiddenButton(){
    this.refs.hiddenButton.click();
  }

  positionSelect(event){
    let user = this.state.user;
    user.job_position_id = event.target.value;
    this.setState({ user: user });
    this.setState({ changed: true });
  }

  componentDidMount(){
    if(this.props.type !== "Candidate"){
      API.get('job_positions/').then((res) => {
        let positions = res.data;
        this.setState({ positions: positions });
      });
    }
    let user_data = Object.assign({}, this.props);
    let user = this.state.user;
    user.first_name = user_data.first_name;
    user.last_name  = user_data.last_name;
    user.mobile_number = user_data.mobile_number;
    this.setState({ user });
  }

  render(){
    let error = this.props.error === "" ? "" : <Error msg={this.props.error} type="error" />;
    let positions = "";
    if(this.props.type !== "Candidate"){
      positions = this.state.positions !== undefined ? <SelectMenu selected={this.props.position} onChange={this.positionSelect} options={this.state.positions}/> : <SelectMenu options={[{ id:0, title:'Loading positions'}]}/>
    }
    return (
      <Animated animationIn="none" animationOut="none" isVisible={true}>
      <div className="profile-info">
      <div className="icons">
        <button className="submitBtns" onClick={() => { this.submitForm() }}><SVG name="save" className="green"/></button>
        <button className="submitBtns"  onClick={() => { this.props.handleShow() }}><SVG name="close" className="red"/></button>
      </div>
      {error}
        <div className = "propicContainer editPic">
          <img alt="eSpace" id="pp" src={this.state.image_preview_url === undefined ? this.props.avatar : this.state.image_preview_url} />
          <span onClick={() => { this.triggerHiddenButton() }}><SVG name="edit2" onClick=""/></span>
          <input ref="hiddenButton" type="file" id="my_file" onChange={(event) => { this.imageUpload(event.target.files) }} hidden/>
        </div>
        <div className="formContainer">
        <form autocomplete="off">
          <InputSection onChange={this.handleChange} name="first_name" type="text" label=" " placeHolder="First Name" defaultValue={this.props.first_name}/>
          <InputSection onChange={this.handleChange} name="last_name" type="text" label=" " placeHolder="Last Name" defaultValue={this.props.last_name}/>
          <div className="emailContainer">
          <input disabled={true} onChange={this.handleChange} name="email" type="text" defaultValue={this.props.email}>
          </input>
          <span>{this.props.email}</span>
          </div>
          <InputSection autocomplete="off" onChange={this.handleChange} name="mobile_number" type="text" label=" " placeHolder="Mobile Number" defaultValue={this.props.mobile_number}/>
          {positions}
          <InputSection autocomplete="off" onChange={this.handleChange} name="password" type="password" label=" " placeHolder="Password"/>
          <InputSection onChange={this.handleChange} name="password_confirmation" type="password" label=" " placeHolder="Confirm Password"/>

        </form>
        </div>
     </div>
     </Animated>
   );
  }
}

ProfileEdit.propTypes = {
  logout: PropTypes.func.isRequired
}

export default withRouter(connect(null, {logout})(ProfileEdit));
