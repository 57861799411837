import React, { Component } from 'react';
import '../../scss/addQ/Add.scss';
import Modal from "./modal";

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false }
  }

  addBtnhandler() {
  
    this.setState({ isOpen: !this.state.isOpen });
  }
  cancelHandler() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  saveHandler() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  render() {
    const modal = (<React.Fragment>
      <div className="page-mask" />
      <Modal addQuestion={this.props.addQuestion} type="essay" cancel={() => this.cancelHandler()} save={() => this.saveHandler()} />
    </React.Fragment>);
    const modalRender = this.state.isOpen ? modal : "";
    return (
      <div>
        <div className="optionsBtnAdd" onClick={this.addBtnhandler.bind(this)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" /></svg></div>
        {modalRender}
      </div>
    );
  }

}

export default Add;
