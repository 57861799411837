import React, { Component } from 'react';
import { FormBtn } from "../FormElements";
import AddChoice from "./choices";
import AddCategory from './AddCategory'
import Radio from "./radio.js";
import TFChoices from './TFChoices'
import "../../scss/addQ/modal.scss";
import { Animated } from "react-animated-css";
import Validation from "./validation";
import { createQuestion } from '../../actions/questionActions.js';
import Error from '../error.js';
import { API } from '../../api';
import Select from "react-select";
import { parse } from 'query-string';

export class Modal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type: "mcq",
      question: "",
      choices: [],
      difficulty: "",
      tags: [],
      time: 0,
      time_min: "",
      time_sec: "",
      error: "",
      question_image: "",
      ansTF: null,
      processingQuestion: false,
      TF_elements:[{is_correct:false, choice_text:"True"},{is_correct:false ,choice_text:"False"}]
    };
    this.handleDifficultyAndTime = this.handleDifficultyAndTime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChoicesAndTags = this.handleChoicesAndTags.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setTags = this.setTags.bind(this)
    this.setQuestionType = this.setQuestionType.bind(this)
    this.handleResetImage = this.handleResetImage.bind(this)
    this.handleTime = this.handleTime.bind(this)
  }
  handleChange(event) {
    this.setState({question: event.target.value})
    this.setState({ error: '' });
  }

  setQuestionType(event){
    this.setState({type: event.key})
  }

  //to get value of true/false from child "choices"
  passState = (val) =>{
    // handling deselecting the current T/F choice
    const deselected = this.state.ansTF === val
    if(deselected){
      this.setState({ansTF: null});
      this.setState({TF_elements: [{choice_text: "True", is_correct: false }, {choice_text:"False", is_correct: false}]});
    }else{
      // if selected a new T/F choice
      this.setState({ansTF: val});
      this.setState({TF_elements: [{choice_text: "True", is_correct: val === "True" }, {choice_text:"False", is_correct: val === "False"}]});
    }
  }

  handleDifficultyAndTime(value, field) {
    let error = "";
    this.setState({ error: error });
    let copy = this.state;
    copy[field] = value;
    let key = "active_" + field;
    copy[key] = value;
    this.setState({ copy });
  }

  handleChoicesAndTags(type, array) {
    let error = "";
    this.setState({ error: error });
    if (type === "choices" || type === "true_false") {
      this.setState({ choices: array });
    } else {
      this.setState({ tags: array });
    }
  }

  setTags(categoryList){
    this.setState({tags: categoryList})
  }

  handleResetImage = () => {
    this.setState({ question_image: "" })
    document.getElementById("question_image_input").value = "";
  }

  handleSubmit() {
    this.setState({processingQuestion: true})
    let error = "";
    let tagsArray = [];
    let answerArray = [];
    let correctAnswer = false;
    for (let i = 0; i < this.state.tags.length; i++) {
      tagsArray.push(String(this.state.tags[i].tagId));
    }
    for (let i = 0; i < this.state.choices.length; i++) {
      let obj = {
        "choice_text": this.state.choices[i].answer_text,
        "is_correct": this.state.choices[i].is_correct
      }
      if (this.props.question === undefined) {
        //Creating a new question
        //obj["id"] = this.state.choices[i].id;
      } else {
        //Editing an existing question
        //Add ID for only old questions
        for (let x = 0; x < this.state.old_choices.length; x++) {
          if (this.state.old_choices[x].answer_text == obj.choice_text) {
            obj["id"] = this.state.old_choices[x].db_id;
          }
        }
      }
      if (obj.is_correct === true) {
        correctAnswer = true;
      }
      answerArray.push(obj);
    }
    if (this.props.question !== undefined) {
      this.state.old_choices.forEach((old_choice) => {
        if (this.state.choices.find(choice => choice.answer_text == old_choice.answer_text) === undefined) {
          let obj = {
            id: old_choice.db_id,
            "choice_text": old_choice.answer_text,
            "is_correct": old_choice.is_correct,
            "_destroy": true
          }
          answerArray.push(obj);
        }
      }
      );
    }
    if (this.state.type === "essay") {
      answerArray = [];
    }
    const question = {
      question_type: this.state.type, //edit done
      title: this.state.question, //edit done
      choices_attributes: answerArray,
      difficulty: this.state.difficulty, //edit done
      category_ids: tagsArray,
      time: parseInt(this.state.time_min ? this.state.time_min * 60 : 0) + parseInt(this.state.time_sec ? this.state.time_sec: 0), //edit done
      user_id: JSON.parse(localStorage.userObject).id, //edit done
      t_f_answer: this.state.ansTF,
      question_image: this.state.question_image
    };
    this.setState({time: question.time});

    if (question.question_type === "true_false" && this.state.ansTF !== null) {
      correctAnswer = true;
    }
    if (!Validation.validateLength(question.title.trim())) {
      error = "Question is empty!"
    }
    else if (question.question_type === "mcq" && !Validation.validateArray(question.choices_attributes, "choices")) {
      error = "Question must at least contain 2 choices!"
    }
    else if (question.question_type === "mcq" && correctAnswer === false) {
      error = "Question must at least contain 1 correct answer!";
    }
    else if(question.question_type === "true_false" && correctAnswer === false){
      error = "Question must contain 1 correct answer!";
    }
    else if (question.difficulty === "") {
      error = "Please select the difficulty level";
    }
    else if (question.time > 1200) {
      error = "Maximum question time is 20 minutes"
    }
    else if (question.time === "" ||  question.time === 0) {
      error = "Question time cannot be zero!"
    }
    else if (!Validation.validateArray(question.category_ids, "tags")) {
      error = "Question must at least contain 1 tag!"
    }else if(question.question_type === "true_false" && this.state.ansTF === null){
      error = "Question must contain 1 correct answer!";
    }
    this.setState({ error: error });
    if (error === "" && this.props.question === undefined) {

      // Convert question object to formdata so we can send the question_image
      let fdata = new FormData();
      for (var key in question) {
        // convert category_ids to send it as array not string as formdata send strings only.
        if (key == "category_ids") {
          question[key].forEach((v) => {
            fdata.append(`${key}[]`, v);
          });
        // convert choices_attributes to send it as stringified object and parse it in the backend.
        } else if (key == "choices_attributes") {
            fdata.append(key, JSON.stringify(question[key]));
        } else {
          fdata.append(key, question[key]);
        }
      }
      createQuestion(fdata).then((res) => {
        this.props.save();
        res.data.tags = this.state.tags;
        res.data.tags = res.data.tags.map((tag) => {
          return tag.answer_text;
        });
        this.props.addQuestion(res.data);
      }).catch((err) => {
        if(err.response.data.message){
          error = err.response.data.message.join('\n')
          this.setState({error})
        }else{
          alert('An error occured')
        }        
      }).finally(() => {
        this.setState({processingQuestion: false})
      });
    }

    if (error === "" && this.props.question !== undefined) {
      if(question.question_type === "true_false"){
        question.choices_attributes = [];
      }

      // Convert question object to formdata so we can send the question_image
      let fdata = new FormData();
      for (var key in question) {
        // convert category_ids to send it as array not string as formdata send strings only.
        if (key == "category_ids") {
          question[key].forEach((v) => {
            fdata.append(`${key}[]`, v);
          });
          // convert choices_attributes to send it as stringified object and parse it in the backend.
        } else if (key == "choices_attributes") {
          fdata.append(key, JSON.stringify(question[key]));
        } else {
            fdata.append(key, question[key]);
        }
        if(fdata.get("question_image") === "" || fdata.get("question_image") === undefined){
          fdata.delete("question_image");
        }
      }
      API.put(`questions/${this.state.id}`, fdata)
        .then((res) => {
          res.data.tags = this.state.tags;
          res.data.tags = res.data.tags.map((tag) => {
            return tag.answer_text;
          });
          this.props.updateQuestion(res.data.id, res.data);
        })
        .catch((err) => {
          alert("An error occured");
        }).finally(() => {
          this.setState({processingQuestion: false})
        });
    }
    if(error !== ""){
      this.setState({processingQuestion: false})
    }
  }

  handleTime(e){
    this.setState({[e.target.name]: e.target.value});
  }
  componentWillMount() {
    if (this.props.question !== undefined) {
      let question = this.props.question;
      question.choices = question.choices.map((choice, k) => {
        return { db_id: choice.id, id: k, answer_text: choice.choice_text, is_correct: choice.is_correct };
      });
      question.tags = question.tags.map((tag, k) => {
        return { id: k, answer_text: tag.text, tagId: tag.id };
      });
      //Clone choices
      let old_choices = JSON.parse(JSON.stringify(question.choices));
      this.setState({ id: question.id, old_choices: old_choices, tags: question.tags, choices: question.choices, type: question.type, time_min: Math.floor(question.time / 60), time_sec:question.time -(Math.floor(question.time / 60)*60) ,difficulty: question.difficulty, question: question.content, active_difficulty: question.difficulty, active_time: question.time });
      if(question.type == "true_false"){
        const trueChoice = question.choices.find(choice => choice.answer_text === 'True')
        const isAnswerTrue = trueChoice.is_correct
        this.setState({TF_elements: [{is_correct:isAnswerTrue, choice_text:"True"},{is_correct:!isAnswerTrue ,choice_text:"False"}], ansTF: isAnswerTrue ? 'True' : 'False'});
      }
    }
  }
  render() {
    //add here for true_false
    const choices = this.state.type === "mcq" ? <AddChoice elements={this.state.choices} type="choices" choices={this.handleChoicesAndTags} error='' /> : "";
    const TF = this.state.type === "true_false" ? <TFChoices elements={this.state.TF_elements} passState={this.passState}  error='' /> : "";
    const title = this.props.question === undefined ? "Add a new question" : "Edit question";
    const button = this.props.question === undefined ? "Add question" : "Update question";
    let reset_image = this.state.question_image ? <a id="reset_image" onClick={this.handleResetImage} >Remove</a> : ""
    let question;
    if (this.props.question !== undefined) {
      question = Object.assign({}, this.props.question);
    }
    const availableQuestionTypes = {
      "mcq": {key: 'mcq', text: 'Multiple Choice'},
      "essay": {key: 'essay', text: 'Essay'},
      "true_false": {key: 'true_false', text: 'T/F'}
    }
    const defaultQuestionType = this.props.question === undefined ? availableQuestionTypes['mcq'] :  availableQuestionTypes[this.state.type]
    return (
      <Animated animationIn="slideInUp" animationOut="slideOutDown" isVisible={true}>
        <div className="modal">

          <header>
            <h3>{title}</h3>
          </header>
          <div className="errorContainer">
            {this.state.error === '' ? '' : <Error msg={this.state.error} type="error" />}
          </div>
          <div className="formContainer">
              <div className="menu">
                <p>Question Type</p>
                <Select
                  isDisabled= {this.props.question !== undefined}
                  id="type-selector"
                  defaultValue={defaultQuestionType}
                  isClearable={false}
                  options={Object.values(availableQuestionTypes)}
                  getOptionLabel={(type) => type.text}
                  getOptionValue={(type) => type.key}
                  classNamePrefix="type-select"
                  onChange={this.setQuestionType}
                  />
              </div>
            <form >
              <textarea placeholder={question !== undefined ? "Type your question" : ""} defaultValue={question !== undefined ? question.content : ""} onChange={this.handleChange} />
              {choices}
              {TF}
              <div className="file-input">
                <p id="question_image_title">Question Image (optional)</p>
                  <input
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    className="input"
                    id="question_image_input"
                    onChange={(e) => {
                      this.setState({ question_image: e.target.files[0] });
                    }}
                  />
                  {reset_image}
              </div>
              <Radio active={this.state.active_difficulty} data={["Easy", "Moderate", "Difficult"]} label="Difficulty" difficulty={this.handleDifficultyAndTime} />
              <div className= "radio">
                <p>Pick Time</p>
                  <div className="span-box"> 
                    <p className="time-label">Min:
                    <input
                    className="time-picker"
                    name="time_min"
                    type="number"
                    min="0"
                    max="20"
                    placeholder= "0"
                    value={this.state.time_min}
                    onChange={this.handleTime}
                  />
                  </p>
                  <p className="time-label">Sec: 
                  <input
                  className="time-picker"
                  name="time_sec"
                  type="number"
                  min="0"
                  placeholder= "0"
                  value={this.state.time_sec}
                  onChange={this.handleTime}
                />
                </p>
                </div>
              </div>
              <div className="category-container">
                <AddCategory selectedCategories={this.state.tags} setCategories={this.setTags} error=''/>
              </div>

              <div className="footer">
                <button onClick={this.props.question === undefined ? this.props.cancel : this.props.exitEditModal}>Cancel</button>
                <FormBtn label={button} loading={this.state.processingQuestion} onClick={this.handleSubmit} />
              </div>
            </form>
          </div>
        </div>
      </Animated>

    );
  }
}

export default Modal;
