import React, {Component} from 'react';
import '../../scss/exam/exam.scss';

class Slider extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const bars=[...Array(this.props.numberOfQuestions)].map((item,i)=>{

      return <div className="slider-bar" id={this.props.current === i+1 ?"current": ""}/>
    });
    return(
      <div className="exam-slider">
        {bars}
      </div>
    );
  }
}

export default Slider;
