import { API, attach_token, detach_token } from "../api";
import { SET_CURRENT_USER } from "./types";

export const setCurrentUser = (token, uid, client) => {
  return {
    type: SET_CURRENT_USER,
    token,
    uid,
    client,
  };
};

//Logout method
export function logout() {
  //Detach token from interceptors (headers)
  detach_token();
  return (dispatch) => {
    return API.delete(`auth/sign_out`).then((res) => {
      dispatch(setCurrentUser("", "", ""));
      //Remove token from localStorage
      localStorage.clear();
    });
  };
}

//Login method
export function login(user) {
  return (dispatch) => {
    return API.post(`auth/sign_in`, user).then((res) => {
      const userObject = res.data.data;
      localStorage.setItem("userObject", JSON.stringify(userObject));
      const CryptoJS = require("crypto-js");
      const token = res.headers["access-token"];
      const uid = res.headers["uid"];
      const client = res.headers["client"];
      //Attach token to headers using interceptors
      attach_token(token, uid, client);
      const encryptedToken = CryptoJS.AES.encrypt(token, "secret key 123");
      //Set item in localStorage
      localStorage.setItem("jwtToken", encryptedToken.toString());
      localStorage.setItem("uid", uid);
      localStorage.setItem("client", client);
      dispatch(setCurrentUser(token, uid, client));
    });
  };
}
//forgot_password
export function forgot_password(user) {
  return (dispatch) => {
    return API.post(`auth/password`, user).then((res) => {});
  };
}
