import React, { Component } from "react";
import "../../scss/exam/markExam.scss";
import { API } from "../../api";
import Error from '../error.js';
import validate from '../form_validations.js';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


class MarkExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      questions: [],
      isLightboxOpen: false,
      selectedQuestionImage: "",
      err: ''
    }
  }
  componentDidMount() {
    //get attempt data from server
    API.get(`/attempts/evaluate/` + this.props.match.params.id.toString()).then((res) => {
      let questions = res.data.questions;
      let array = [];
      for (let i = 0; i < questions.length; i++) {
        let current = {
          id: i + 1,
          questionId: questions[i].id,
          text: questions[i].question_text,
          essay: questions[i].essay_text,
          image: questions[i].question_image,
          choices: [],
          mark: null
        }
        array.push(current);
      }
      this.setState({
        questions: array,
        title: res.data.exam_title,
      });
    });
  }
  //handles marking a question
  handleMark = (e) => {
    let stateCopy = this.state.questions;
    stateCopy[e.target.id[e.target.id.length - 1]].mark = e.target.value;
    this.setState({
      questions: stateCopy,
      err: ''
    });
  }
  componentWillMount() {
    window.onscroll = function () {
    }
  }
  handleSubmit = () => {
    let essay_marks = [];
    let err = "";
    //shows an error before submitting if some question is not marked
    for (let i = 0; i < this.state.questions.length; i++) {
      if (this.state.questions[i].mark == null || this.state.questions[i].mark == '') {
        err = "question " + this.state.questions[i].id + " is not marked!";
        break;
      }
      //shows an error before submitting if the mark value is not in the range from 0 to 5
      else if (!validate.number(this.state.questions[i].mark) || this.state.questions[i].mark > 5 || this.state.questions[i].mark < 0) {
        err = "please enter an integer between 0 and 5 to mark question " + this.state.questions[i].id;
        break;
      }
      else {
        let obj = {
          id: this.state.questions[i].questionId,
          mark: this.state.questions[i].mark
        }
        essay_marks.push(obj);
      }
    }
    if (err != "") {
      this.setState({ err: err });
    }
    else {
      const object = {
        essay_marks: essay_marks
      }
      //submit the corrected attempt to the server
      API.post(`/attempts/evaluate/` + this.props.match.params.id.toString(), object).then((res) => {
        this.props.history.push("/me/applications")
      }).catch((err) => {
      });
    }
  }
  render() {
    const items = this.state.questions.map((item, i) => {
      let answers = [];
      if (item.choices.length === 0) {
        answers = <p className="essay">{item.essay}</p>;
      } else {
        item.choices.map((choice, j) => {
          let choiceClass = "";
          if (choice.isChecked && choice.isCorrect) {
            choiceClass = "correct";
          } else if (choice.isChecked && (!choice.isCorrect)) {
            choiceClass = "wrong";
          }
          answers.push(<li key={j} className={choiceClass}>{choice.text}</li>);
        })
      }
      return (
        <div className="mark-question" key={i} id={"q" + i}>
          <p>{i + 1 + "." + item.text} </p>
          {item.image ? (
            <span
              className="view-image"
              onClick={() =>
                this.setState({
                  isLightboxOpen: true,
                  selectedQuestionImage: item.image,
                })
              }
            >
              <span className="material-icons">
                insert_photo
              </span>
              View Image
            </span>
          ) : null}
          <ul>
            {answers}
            {item.choices.length === 0 ? (
              <div className="markInput">
                <input
                  id={"input" + i}
                  onChange={(e) => this.handleMark(e)}
                  placeholder="Mark"
                />
                <span>/ 5</span>
              </div>
            ) : null}
          </ul>
        </div>
      );
    });
    const list = this.state.questions.map((q, i) => (
      <li ><a href={"#q" + i} className={q.choices.length > 0 || (q.mark !== null && q.mark !== '') ? "done" : ""}>{q.text.length >= 15 ? (i + 1) + "." + q.text.substring(0, 15) + ".." : (i + 1) + "." + q.text}</a></li>
    ));
    return (
      <div className="wrapper mark-exam">
        <div className="application-info">
          {this.state.err === '' ? '' : <Error msg={this.state.err} type="error" />}
          <h1>{this.state.title}</h1>
          <ul>
            {list}
          </ul>
          {this.state.isLightboxOpen && (
            <Lightbox
              mainSrc={this.state.selectedQuestionImage}
              onCloseRequest={() => this.setState({ isLightboxOpen: false, selectedQuestionImage: "" })}
            />
          )}
          <a id="toTop" href="#q0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" /></svg>
          </a>
          <button id="submitExamReview" onClick={this.handleSubmit}>Submit</button>
        </div>
        {items}

      </div>
    );
  }
}
export default MarkExam;
