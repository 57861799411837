import React, { Component } from 'react';
import '../../../scss/exam/submitted.scss';
import guidePic from '../../../images/guide-pic.png';

class ExamSubmitted extends Component {
  handleClick = (e) => {
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="submitted-page">
        <img style={{transform: 'scaleX(-1)'}} className="image_guide" src={guidePic} />
        <div className="submitted-container">
          <div className="submitted-info">
            <section className="info-section">
              <h1 className="info-title">Your exam has been submitted!</h1>
              <span>We'll update you with the results once the review process is complete</span>
            </section>
            <section className="view-applications">
              <button onClick={this.handleClick}>
                <svg style={{transform: 'scaleX(-1)'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 12l-18 12v-24z" /></svg>
                <span>View Applications</span>
              </button>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ExamSubmitted;
