import React, { Component } from "react";
import SidePanel from "./side-panel";
import Slider from "./slider";
import Question from "./question.js";
import { withAlert } from "react-alert";
import { API } from "../../api";
import "../../scss/exam/exam.scss";
import "../../scss/addQ/modal.scss";
import FullPageLoader from "../FullPageLoader/FullPageLoader"
import ScrollToContinue from "./scroll-to-continue";
import { disableInspection } from "../../utils.js";
/*
  this class shows the exam that show to the candidate to solve
  it contains:
  Question: shows all questions of the exam
  SidePanel: the panel in the left that shows questions title and the submit button
  modal: when candidate click on submit then a modal with confirmation message shown
*/
class NormalExam extends Component {
  constructor(props) {
    super(props);
    this.candidateUpdateAnswer = false;
    // used as a way to just modify draft of the modiefied answers
    this.temporalAnswer = new Set()

    this.state = {
      modalOpen: false,
      loading: true,
      examTime: 0, //Minutes
      currentQuestion: 1,
      shouldShowScrollGuide: false,
      // scrolledOnce is used to prevent scroll guide from showing again if the user scrolled at least once
      scrolledOnce: false,
      questions: [
        {
          text: "What is the difference between SQL and Non-SQL databases?",
          isRadio: true,
          choices: [],
        },
        {
          text: "How are you today?",
          isRadio: false,
          choices: ["fine", "meh", "good"],
        },
        {
          text: "How old are you?",
          isRadio: true,
          choices: ["11-18", "18-21", "21-30"],
        },
        {
          text: "What's your name?",
          isRadio: false,
          choices: ["I won't tell you", "none of your business!", "ugh fine"],
        },
      ],
      listStatus: [],
      answers: {
        mcq: [],
        essay: [],
        true_false: []
      },
    };
  }


  handleStatusChange = (q_index, isDone) => {
    let statusCopy = this.state.listStatus;
    statusCopy[q_index].isDone = isDone;
    this.setState({ listStatus: statusCopy });
    if(!this.state.scrolledOnce && q_index === 0 && isDone){
      this.setState({shouldShowScrollGuide: true})
    }
  };
  goBack = () => {
    this.props.history.push("/");
  }
  /*
  * when the candidate remove the answer from a question then remove the answer from the state
  * which will remove the mark of solved questions in the sidepanel
  */
  removeAnswer = (id, type) => {
    this.candidateUpdateAnswer = true;
    let answers = Object.assign({}, this.state.answers);
    if (type === "mcq") {
      //mcq handling
      answers.mcq = answers.mcq.filter((answer) => {
        if (answer.choice_id === id) {
          this.temporalAnswer.add(answer.question_id);
        }
        return answer.choice_id !== id;
      });
    }
    //true_false handling
    else if(type === "true_false"){
      answers.true_false = answers.true_false.filter((answer) => {
        if (answer.choice_id === id) {
          this.temporalAnswer.add(answer.question_id);
        }
        return answer.choice_id !== id;
      });
    }
    else {
      //Essays handling
      answers.essay = answers.essay.filter((answer) => {
        //Question ID
        if (answer.question_id === id) {
          this.temporalAnswer.add(answer.question_id);
        }
        return answer.question_id !== id;
      });
    }
    this.setState({ answers });
  };
  /* update the answer state of a question - change without submit to the server (just update state)*/
  submitAnswer = (submittedAnswer, type, singleAnswer=false) => {
    this.candidateUpdateAnswer = true;
    this.temporalAnswer.add(submittedAnswer.question_id);

    let answers = Object.assign({}, this.state.answers);
    if (type === "mcq") {
      if (singleAnswer){
        // Removing any selected choice for this question
        answers.mcq = answers.mcq.filter((answer) => {
          return answer.question_id !== submittedAnswer.question_id;
        });
      }
      answers.mcq.push(submittedAnswer);
    }
    else if(type === "true_false"){
      answers.true_false = answers.true_false.filter((answer) => {
        return answer.question_id !== submittedAnswer.question_id;
      });
      answers.true_false.push(submittedAnswer);
    }
     else {
      //Essays handling
      let exists = false;
      answers.essay = answers.essay.map((answer) => {
        if (answer.question_id === submittedAnswer.question_id) {
          answer.essay_text = submittedAnswer.essay_text;
          exists = true;
        }
        return answer;
      });
      if (exists === false) {
        answers.essay.push(submittedAnswer);
      }
    }
    this.setState({ answers });
  };
  /*
  * loading the exam from the server and update the state or returning the the main home page if an error occured
  */
  componentDidMount() {
    API.get(`attempts/${this.props.match.params.id}?exam_type=normal`)
      .then((res) => {
        for (let i = 0; i < res.data.exam.questions.length; i++) {
          this.setState((stateCopy) => ({
            listStatus: stateCopy.listStatus.concat({
              isActive: false,
              isDone: false,
            }),
          }));
        }
        this.setState({
          questions: res.data.exam.questions,
          examTime: Math.ceil(res.data.remaining_time),
          answers: res.data.answers,
          loading: false,
        });
      })
      .catch((err) => {
        if (err.response.status === 405) {
          alert("You already submitted this exam before");
        } else if(err.response.status === 422){
          this.props.history.push("/");
        } else {
          alert("Error: " + err);
        }
        this.props.history.push("/");
      });
    disableInspection();
  }
  /*
  * update the state with the question that already viewed by the candidate
  */
  setQuestion = (question) => {
    this.setState({ currentQuestion: question });
    if(!this.state.scrolledOnce){
      this.setState({scrolledOnce: true, shouldShowScrollGuide: false})
    }
  };
  /* submit the answer to the server when the candidate submit the exam or the time ended */
  handleSubmission = () => {
    this.setState({loading: true});
    localStorage.removeItem(
      "visitedGuide" + this.props.match.params.id.toString()
    );

    API.put(`attempts/${this.props.match.params.id}`, this.state.answers)
      .then((res) => {
        this.setState({loading: false});
        this.props.history.push("/me/attempt/submitted");
      })
      .catch((err) => {
        this.props.history.push("/");
        this.props.alert.show(
          'your exam failed to submit but do not worry we saved your answers'
        );
      });
  };

  /* submit the answer to the server as a draft when there is an update to the answer in the state*/
  handleDraftSubmission = (isClicked) => {
    if (!this.candidateUpdateAnswer) {
      if (isClicked) {
        this.props.alert.removeAll()
        this.props.alert.show(
          'your answers already saved'
        );
      }
      return;
    }
    this.candidateUpdateAnswer = false;
    let answers = Object.assign({}, this.state.answers);
    let changedQuestions = new Set(this.temporalAnswer);
    this.temporalAnswer.clear();

    // only changed answers will be sent
    answers.mcq = answers.mcq.filter((answer) => {
      return changedQuestions.has(answer.question_id);
    });
    answers.true_false = answers.true_false.filter((answer) => {
      return changedQuestions.has(answer.question_id);
    });
    answers.essay = answers.essay.filter((answer) => {
      return changedQuestions.has(answer.question_id);
    });
    answers.changedQuestions = Array.from(changedQuestions)
    API.post(`attempts/draftsubmit/${this.props.match.params.id}`, answers)
      .then((res) => {
        if (isClicked) {
          this.props.alert.removeAll()
          this.props.alert.success(
            'your answers saved!'
          );
        }
      })
      .catch((err) => {
        if (isClicked) {
          this.props.alert.removeAll()
          this.props.alert.show(
            'check your connection. failed to save answers!'
          );
        }
        this.candidateUpdateAnswer = true;
        this.temporalAnswer = new Set([...this.temporalAnswer, ...changedQuestions])
      });
  };
  /* calculate number of questions that not yet have  answer by the candidate to show in the confirmation of submit exam*/
  anyUnansweredQuestions=()=>{
    let answeredQuestions=[]
    this.state.answers.mcq.forEach((answer)=>{
      if(!answeredQuestions.includes(answer.question_id))
      {
        answeredQuestions.push(answer.question_id)
      }
    })
    this.state.answers.true_false.forEach((answer)=>{
      if(!answeredQuestions.includes(answer.question_id))
      {
        answeredQuestions.push(answer.question_id)
      }
    })
    this.state.answers.essay.forEach((answer)=>{
      if(!answeredQuestions.includes(answer.question_id))
      {
        answeredQuestions.push(answer.question_id)
      }
    })
    if(this.state.questions.length-answeredQuestions.length===1){
      return `Note: You still have ${this.state.questions.length-answeredQuestions.length} unanswered question`
      }
    if(answeredQuestions.length<this.state.questions.length){
    return `Note: You still have ${this.state.questions.length-answeredQuestions.length} unanswered questions`
    }

  }
  /* to open the verification model of submiting the exam */
  handleEnsuringSubmission = () => {
    this.setState({ modalOpen: true });
  };
  render() {
    let modal;
    if (this.state.modalOpen) {
      modal = (
        <div className="popup">
          <React.Fragment>
            <div className="page-mask" >

              <div className="modal" isVisible={true}>
                <header>
                  <h3>Are you sure you want to submit?</h3>
                </header>
                <div className="errorContainer alert" >
                 {this.anyUnansweredQuestions()?this.anyUnansweredQuestions():null}


          </div>
                <div className="formContainer">

                  <form>
                    <div className="footer">
                      <div style={{ marginRight: "10px" }}>
                        <button
                        className="popupButton"

                          onClick={this.handleSubmission}
                        >Submit Exam </button>
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        <button
                        className="popupButton"

                          onClick={() => this.setState({ modalOpen: false })}
                        >Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      );
    }
    let answerMap = new Map()
    if (this.state.loading == false) {
      let answers = Object.assign({}, this.state.answers);
      for (let i = 0; i < answers.essay.length; i++) {
        let answer = answers.essay[i]
        answerMap.set(answer.question_id, answer.essay_text)
      }
      for (let i = 0; i < answers.true_false.length; i++) {
        let answer = answers.true_false[i]
        answerMap.set(answer.question_id, answer.answer)
      }
      for (let i = 0; i < answers.mcq.length; i++) {
        let answer = answers.mcq[i]
        answerMap.has(answer.question_id) ? answerMap.get(answer.question_id).add(answer.choice_id) :
                                           answerMap.set(answer.question_id, new Set([answer.choice_id]))
      }
    }

    let content =
      this.state.loading === true ? (
        <FullPageLoader />
      ) : (
        <div className="exam-container">
          {(this.state.shouldShowScrollGuide && this.state.currentQuestion === 1)
          && <ScrollToContinue/>}
          <SidePanel
            handleSubmission={this.handleSubmission}
            handleDraftSubmission={this.handleDraftSubmission}
            handleEnsuringSubmission={this.handleEnsuringSubmission}
            question={this.state.currentQuestion}
            time={this.state.examTime}
            questions={this.state.questions}
            status={this.state.listStatus}
            submitBtnText={"Submit"}
            examType="normal"
            questionsNum={this.state.questions.length}
            goBack={this.goBack}
          />
          <Slider
            numberOfQuestions={this.state.questions.length}
            current={this.state.currentQuestion}
          />
          <Question
            removeAnswer={this.removeAnswer}
            submitAnswer={this.submitAnswer}
            currentQuestion={this.state.currentQuestion}
            setQuestion={this.setQuestion}
            questions={this.state.questions}
            statusHandler={this.handleStatusChange}
            answerMap={answerMap}
          />
          {modal}
        </div>
      );
    return content;
  }
}

export default withAlert()(NormalExam);
