import React, { Component } from "react";
import "../../scss/exam/scroll-to-continue.scss";


class ScrollToContinue extends Component {
    render() {
        return (
            <div class="hero">
                <a href="#question2" className="scroll-down">
                    <div class="mouse">
                        <span></span>
                    </div>
                    <div className="arrow">
                        <span></span>
                    </div>
                    <span className="scroll-text">
                        Scroll To Continue
                    </span>
                </a>

            </div>
        )
    }

}

export default ScrollToContinue