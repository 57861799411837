export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};


export const disableInspection = () => {
  // Disable right-click
  document.addEventListener('contextmenu', (e) => e.preventDefault());

  document.addEventListener("keydown", e => {
    // DISABLE CONTROL AND F12
    console.log(e.ctrlKey);
    if (e.ctrlKey || e.keyCode==123) {
      e.stopPropagation();
      e.preventDefault();
    }
  });
}

export const isValidURL = (url) => {
  const trimmedURL = url.trim()
  const regex = /^(https:\/\/)(www\.)?([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/g;
  return regex.test(trimmedURL);
}
