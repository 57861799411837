import React, { Component } from "react";
import "../../scss/show.scss";
import SVG from "../../svg.js";
import {Animated} from "react-animated-css";

class ProfileShow extends Component {


  render(){
    var handleEdit = this.props.handleEdit;
    return (
      <Animated animationIn="slideInRight" animationOut="slideOutRight" isVisible={true}>
      <div className="profile-info">
      <div className="icons">
        <span onClick={() => handleEdit()}><SVG name="edit" /></span>
        <span onClick={() => this.props.toggleProfile() }> <SVG name="close" className="red"/> </span>
      </div>
      <div className = "propicContainer">
        <img alt="eSpace" id="pp" src={this.props.avatar} />
      </div>
        <div className="title">
          <h3>{this.props.first_name + " " + this.props.last_name}</h3>
        </div>
        <ul>
          <li className="email"><SVG name="email" />Email <span>{this.props.email}</span></li>
          <li className="phone"><SVG name="phone" />Mobile Number <span>{this.props.mobile_number}</span></li>
        </ul>
     </div>
     </Animated>
    );
  }

}

export default ProfileShow;
