import React from 'react';
import InputSection, { CheckboxSection, FormBtn } from "../FormElements.js";
import Validate from "../form_validations";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../actions/authActions.js';
import { NavLink } from "react-router-dom";
import Error from '../error.js';

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
      remember_me: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    //redirect user if already logged in
    if (localStorage.client) {
      if (JSON.parse(localStorage.userObject).type === "Candidate") {
        this.props.history.replace("/me/applications");
      }
      else {
        this.props.history.replace('/me/my_questions');
      }
    }
  }

  handleCheck(event) {
    this.setState({ remember_me: !this.state.remember_me });
  }
  //handles input fields change
  handleChange(event, field) {
    let copy = this.state;
    copy[field] = event.target.value;
    this.setState({ copy });
    this.setState({ error: '' });
  }

  handleSubmit() {
    let error = '';
    //Validate user data
    if (!Validate.not_empty(this.state.email) || !Validate.not_empty(this.state.password)) {
      error = 'Please fill in all fields';
    }
    else if (!Validate.password(this.state.password)) {
      error = 'Invalid Email and password combination';
    }
    else if (!Validate.validEmail(this.state.email)) {
      error = 'Email is not valid';
    }
    this.setState({ error: error });
    if (error === '') {
      //Initialize Object to be dispatched
      const user = {
        email: this.state.email,
        password: this.state.password,
        remember_me: this.state.remember_me,
      };
      //Call Thunk action to login and set the token in Redux store
      this.props.login(user).then(
        (res) => {
          if (localStorage.redirect_url) {
            let str = localStorage.redirect_url;
            localStorage.removeItem('redirect_url');
            this.props.history.push(str);
          }
          else {
            JSON.parse(localStorage.userObject).type === "Candidate" ?
              this.props.history.push("/me/applications") : this.props.history.push('/me/my_questions')
          }
        },
        (err) => this.setState({ error: 'Invalid Email and password combination' })
      );
    }
  }
  render() {
    return (
      <div className="formContainer root-form">
        {this.state.error === '' ? '' : <Error msg={this.state.error} type="error" />}
        <form method="post" noValidate>
          <InputSection type="text" label="Email" name="email" onChange={this.handleChange} />
          <InputSection type="Password" name="password" onChange={this.handleChange} />
          <NavLink to="/forgot_password">
            Forgot Password?

              </NavLink>
          <CheckboxSection onChange={this.handleCheck} />
          <FormBtn label="Continue to Dashboard" onClick={this.handleSubmit} />
        </form>
      </div>
    )
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired
}

export default connect(null, { login })(Login);
