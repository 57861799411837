import React, { Component } from "react";
import "../../scss/addQ/Add.scss";
import "../../scss/addQ/modal.scss";
import "../../scss/addQ/ImportStatus.scss";
import { Animated } from "react-animated-css";

class ImportStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.closeModal = this.closeModal.bind(this)
  }

  closeModal(){
    this.props.hideImportStatus()
  }

  render() {
    const importFailed = this.props.validQuestions ? false : true
    const modal = (
      <React.Fragment>
        <div className="page-mask" />
        <Animated
          animationIn="slideInUp"
          animationOut="slideOutDown"
          isVisible={true}
        >
          <div className="modal">
            <div className="close-div">
              <i
                className="material-icons close-icon"
                onClick={this.closeModal}
              >
                close
              </i>
            </div>
            <header>
              <h3>Import {importFailed ? 'Failed' : 'Complete'}!</h3>
              <div className="status-icon">
                <i className={`material-icons md-75${importFailed ? ' failed-import': ''}`}>{importFailed ? 'error' : 'check_circle'}</i>
              </div>
            </header>
            <div className="content">
              <div className="questions-status">
                {importFailed ? 
                  null
                :
                (<span className="questions-success">
                    <i className="material-icons success-color">check_circle</i>
                    &nbsp;{this.props.validQuestions} Question
                    {this.props.validQuestions > 1 ? "s" : ""} have been created
                    successfully
                  </span>
                )
              }

                <span className="questions-failed">
                  <i className="material-icons fail-color">cancel</i>&nbsp;
                  {this.props.invalidQuestions} Question
                  {this.props.invalidQuestions > 1 ? "s" : ""} could not be
                  created
                </span>
              </div>
              <p className="download-tip">
                Please download this CSV file containing the questions that
                could not be added along with the problems that need to be
                resolved then import again
              </p>
              <a className="download-btn" href={this.props.downloadLink}>
                Download&nbsp;<i className="material-icons">get_app</i>
              </a>
              <p className="errors-tip">
                * You will find a column at the end pointing the errors for each
                question, you do not have to remove it before importing
              </p>
            </div>
          </div>
        </Animated>
      </React.Fragment>
    );
    const modalRender = this.state.isOpen ? modal : "";
    return <div class="status-modal">{modalRender}</div>;
  }
}

export default ImportStatus;
