import React, { Component, createRef } from "react";
import "../../scss/addQ/Add.scss";
import Select from "react-select";
import Error from "../error.js";
import "../../scss/addQ/import.scss";
import Radio from "./radio.js";
import { getTagsList } from "../../actions/questionActions.js";
import { Animated } from "react-animated-css";
import { API, DEFAULT_URL } from "../../api";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

class Add extends Component {
  constructor(props) {
    super(props);
    this.defaultSettings = createRef();
    this.state = {
      isOpen: false,
      categories: [],
      error: "",
      difficulty: "",
      time_min: "",
      time_sec: "",
      selectedCategories: [],
      excelFile: "",
      defaultSettingsOn: false,
      isImporting: false,
    };
  }

  componentDidMount() {
    getTagsList().then((res) =>{
      // Creating a group of categories for each parent category (that has any subcategories) 
      // with the parent as the label and children as the group
      const othersGroup = { label: "Others", options : []}
      let groupedOptions = []
      res.data.forEach((cat) => {
        if (cat.subcategories.length > 0){
          groupedOptions.push({label: cat.title, options: cat.subcategories})
        // If this category has no parent, add it to 'Others' group
        }else if(!cat.parent_category_id){
          othersGroup.options.push(cat)
        }
      })
      if (othersGroup.options.length > 0){
        groupedOptions.push(othersGroup)
      }
      this.setState({ categories: groupedOptions });
    });
  }

  setSelectedCategories = (categories) => {
    this.setState({ error: "" });
    if (!categories || categories.length < 11) {
      this.setState({ selectedCategories: categories });
    } else {
      this.setState({
        error: "Maximum number of selected categories reached!",
      });
    }
  };

  handleDifficultyAndTime = (value, field) => {
    let error = "";
    this.setState({ error: error });
    let copy = this.state;
    copy[field] = value;
    let key = "active_" + field;
    copy[key] = value;
    this.setState({ copy });
  };
  handleDefaultToggle = () => {
    //toggling the checkbox default setting option
    this.setState({ defaultSettingsOn: !this.state.defaultSettingsOn });
    this.defaultSettings.current.className == "disabledDiv"
      ? (this.defaultSettings.current.className = "")
      : (this.defaultSettings.current.className = "disabledDiv");
    if (this.defaultSettings.current.className == "disabledDiv") {
      this.setState({ difficulty: "", time_min: "", time_sec: "", selectedCategories: [] });
    }
  };
  //handling submit...
  handleSubmit = () => {
    //checking if there is a file
    if (this.state.excelFile === "" || this.state.excelFile === undefined)
      return this.setState({ error: "Please import a questions file." });

    if (this.state.defaultSettingsOn) {
      // Ensuring that at least one default value is set
      if (
        !(
          this.state.difficulty ||
          this.state.time_min || this.state.time_sec ||
          (this.state.selectedCategories &&
            this.state.selectedCategories.length != 0)
        )
      ) {
        return this.setState({
          error: "Set at least on default value or disable default settings",
        });
      }
    }
    this.setState({ isImporting: true });
    let data = new FormData();
    const selectedCategoriesIds = this.state.selectedCategories
      ? this.state.selectedCategories.map((val) => val.id)
      : [];
    data.append("default_difficulty", this.state.difficulty);
    data.append("default_time", parseInt(this.state.time_min ? this.state.time_min * 60 : 0) + parseInt(this.state.time_sec ? this.state.time_sec: 0));
    data.append("default_category_ids", JSON.stringify(selectedCategoriesIds));
    data.append("csv_file", this.state.excelFile, this.state.excelFile.name);

    this.setState({ error: null });

    API.post("/questions/importcsv", data)
      .then((res) => {
        if (!res.data.saved_rows && !res.data.invalid_rows)
          return this.setState({ error: "Imported File is empty." });
        this.setState({ isOpen: false }, () => {
          this.props.showImportStatus(
            res.data.saved_rows,
            res.data.invalid_rows,
            res.data.download_link
          );
        });
      })
      .catch((res) => {
        this.setState({ error: "Invalid CSV file" });
      })
      .finally(() => {
        this.setState({
          isImporting: false,
          difficulty: "",
          selectedCategories: [],
          time_min: "",
          time_sec: "",
          defaultSettingsOn: false,
        });
      });
  };

  handleTime = (e) =>{
    this.setState({[e.target.name]: e.target.value});
  };

  render() {
    const formatGroupLabel = data => (
      <div className="category-group">
        <span>{data.label}</span>
        <span className="category-group-badge">{data.options.length}</span>
      </div>
    );

    const modal = (
      <React.Fragment>
        <div className="page-mask" />
        <Animated
          animationIn="slideInUp"
          animationOut="slideOutDown"
          isVisible={true}
        >
          <div className="modal">
            <header>
              <h3>Import Questions </h3>
            </header>
            <div className="errorContainer">
              {this.state.error ? (
                <Error msg={this.state.error} type="error" />
              ) : (
                ""
              )}
            </div>
            <div className="formContainer">
              <div className="fileInput">
                <h3>Import excel sheet(.csv) </h3>
                <input
                  type="file"
                  accept=".csv"
                  className="input"
                  onChange={(e) => {
                    this.setState({ excelFile: e.target.files[0] });
                  }}
                  accept=".csv"
                />
              </div>

              <div className="checkbox defaultCheck">
                <label className="checboxLabel">Enable Default Settings</label>
                <input
                  type="checkbox"
                  id="check"
                  onChange={this.handleDefaultToggle}
                />
              </div>
              <form className="disabledDiv" ref={this.defaultSettings}>
                <p className="note">
                  Note: Any empty values in the imported file will be set by
                  default to the values below.{" "}
                </p>

                <div className="category-container">
                  <div className="add-category">
                    <Select
                      value={this.state.selectedCategories}
                      isMulti
                      placeholder="Categories"
                      closeMenuOnSelect={false}
                      options={this.state.categories}
                      getOptionLabel={(category) => category.title}
                      getOptionValue={(category) => category.title}
                      formatGroupLabel={formatGroupLabel}
                      className="category-multi-select"
                      classNamePrefix="select"
                      onChange={this.setSelectedCategories}
                    />
                  </div>
                </div>
                <Radio
                  active={this.state.difficulty}
                  data={["Easy", "Moderate", "Difficult"]}
                  label="Difficulty"
                  difficulty={this.handleDifficultyAndTime}
                />
                <div className= "radio">
                <p>Pick Time</p>
                  <div className="span-box"> 
                    <p className="time-label">Min:
                    <input
                    className="time-picker"
                    name="time_min"
                    type="number"
                    min="0"
                    max="20"
                    placeholder= "0"
                    value={this.state.time_min}
                    onChange={this.handleTime}
                  />
                  </p>
                  <p className="time-label">Sec: 
                  <input
                  className="time-picker"
                  name="time_sec"
                  type="number"
                  min="0"
                  placeholder= "0"
                  value={this.state.time_sec}
                  onChange={this.handleTime}
                />
                </p>
                </div>
              </div>
              </form>
              <div className="sample-csv">
                <hr />
                <p className="note">
                  Download this sample excel sheet to guide you on how a
                  questions excel sheet should look like
                </p>
                <div className="right">
                  <a
                    className="submitExcel"
                    href={`${
                      DEFAULT_URL.match(/\S*\.\w*/)[0]
                    }/public/questions/Import-questions-template.csv`}
                  >
                    Sample Excel Sheet
                  </a>
                </div>
              </div>

              <div className="footerActions">
                {this.state.isImporting ? null : (
                  <button
                    onClick={(e) => {
                      this.setState({
                        isOpen: false,
                        error: null,
                        excelFile: "",
                      });
                    }}
                  >
                    Cancel
                  </button>
                )}
                <button
                  disabled={this.state.isImporting}
                  className="import-submit"
                  onClick={this.handleSubmit}
                >
                  {this.state.isImporting ? (
                    <Loader
                      type="ThreeDots"
                      color="#FFFFFF"
                      height={27}
                      width={27}
                    />
                  ) : (
                    <span>Import Questions</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Animated>
      </React.Fragment>
    );
    const modalRender = this.state.isOpen ? modal : "";
    return (
      <div>
        <div
          className="optionsBtnImport"
          onClick={() => {
            this.setState({ isOpen: true });
          }}
        >
          <img className="importSVG" src={require("../../images/import.svg")} />
        </div>
        {modalRender}
      </div>
    );
  }
}

export default Add;
