import React, { Component, createRef } from "react";
import "../../scss/applications.scss";
import { Link, withRouter, Switch, Route } from "react-router-dom";
import { API } from "../../api";
import emptyStateSVG from "../../images/emptyState.svg";
import AddExam from "../addExam/addExam.js";
import Radio from './../addQ/radio'
import {ApplicationPlaceholder} from './submissions'
import {capitalize} from '../../utils'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css';


//all exams
export class ExamsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exams: [],
      per_page: 9,
      current_page: 1,
      total_exams: 0,
      examsLoading:false,
      selectedTypeFilter: 'All',
      filter: false,
      author: true
    };
  }

  componentDidMount() {
    //get list of EXAMS, should be configured after the creation is done
  
    this.fetch_exams(1);
  }
  //get list of evaluated exams
  fetch_exams = (pageNumber, filter = null) => {
    if(filter === null){
    this.setState({filter: false, examsLoading:true})
    API.get(
      `/exams?page=${pageNumber}&per_page=${this.state.per_page}`
    ).then((res) => {
      this.setState({examsLoading:false,exams:res.data.exams,total_exams:res.data.total_exams,last_page: Math.ceil(res.data.total_exams / this.state.per_page),current_page: pageNumber,

      })
    });
  }
  else{
    this.setState({filter: true, examsLoading:true});
      API.get(filter + `&page=${pageNumber}&per_page=${this.state.per_page}`)
        .then((res) => {
          this.setState({current_page: pageNumber });
          if(res.status === 204){
            this.setState({exams: []});
          }
          this.setState({examsLoading:false,exams:res.data.exams,total_exams:res.data.total_exams,last_page: Math.ceil(res.data.total_exams / this.state.per_page),current_page: pageNumber});
        })
        .catch((err) => {
          this.setState({ error: err });
        });
      }
  };
  refreshExams(page) {
    this.setState({ current_page: page });
    if(this.state.author === false || this.state.selectedTypeFilter !== "All"){
      const typeFilterQueryParam = this.state.selectedTypeFilter !== 'All' ? `&type=${this.state.selectedTypeFilter.toLowerCase()}` : ""
      let req = `/exams?user_id=${ this.state.author === false ? JSON.parse(localStorage.userObject).id : ""}${typeFilterQueryParam}`;
      this.fetch_exams(page, req);
    }
    else{
      this.fetch_exams(page);
    }
  }

  handleAuthor = (e) => {
    this.setState({ author: !this.state.author }, () => {
      this.refreshExams(this.state.current_page)
    });
  }

  handleExamTypeFilter = (value, _) => {
    this.setState({selectedTypeFilter: value}, () => {
      this.refreshExams(this.state.current_page)
    })
  }

  render() {
    const examTypesTips = {
      normal:
        "The candidate can solve the questions in any order he desires, and the time limit is on the exam as a whole.",
      restricted:
        "The candidate must solve the questions in a specific order, and there's a time limit on every question."
    };
    const pageNumbers = [];
    for (let i = 1; i <= this.state.last_page; i++) {
      pageNumbers.push(i);
    }
    const pages = pageNumbers.map((number, i) => {
      let classes = this.state.current_page === i + 1 ? "active" : "";
      let temp;
      if (this.state.total_exams >= 10) {
        if (
          number === this.state.total_exams ||
          (number >= this.state.current_page - 1 &&
            number <= this.state.current_page + 1)
        ) {
          return (temp = (
            <span
              key={number}
              className={classes}
              onClick={(number) => this.refreshExams(i + 1)}
            >
              {" "}
              {number}{" "}
            </span>
          ));
        }
      } else {
        return (temp = (
          <span
            key={number}
            className={classes}
            onClick={(number) => this.refreshExams(i + 1)}
          >
            {" "}
            {number}{" "}
          </span>
        ));
      }

      return temp;
    });
    const back_arrows =
      this.state.current_page === 1 ? (
        ""
      ) : (
        <div>
          <svg
            id="rotate"
            onClick={() => this.refreshExams(1)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
          </svg>
          <svg
            onClick={
              this.state.current_page === 1
                ? ""
                : () => this.refreshExams(this.state.current_page - 1)
            }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
          </svg>
        </div>
      );
    const front_arrows =
      (this.state.examsLoading || !this.state.total_exams || this.state.current_page === this.state.last_page) ? (
        ""
      ) : (
        <div>
          <svg
            onClick={
              this.state.current_page === this.state.last_page
                ? ""
                : () => this.refreshExams(this.state.current_page + 1)
            }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
          </svg>
          <svg
            onClick={() => this.refreshExams(this.state.last_page)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
          </svg>
        </div>
      );
    const exams = this.state.exams.map((item, i) => (
      <div className="application application-exam" key={i}>
        <div className="ribbon-container">
          <Tooltip
            placement="right"
            destroyTooltipOnHide={false}
            trigger='hover'
            overlay={<div>{examTypesTips[item.exam_type]}</div>}
          >
            <a className="ribbon">{capitalize(item.exam_type)}</a>
          </Tooltip>
          
        </div>
        <section>
          <span className="label">ID</span>
          <div className="value ">{item.id}</div>
        </section>
        <section>
          <span className="label">Exam Title</span>
          <div className="value fixed-width-date">{item.exam_title}</div>
        </section>
        <section>
        <span className="label">Questions</span>
        <div className="value fixed-width-duration">{item.questions_count === 0? "Not added": item.questions_count}</div>
      </section>
        <section>
          <span className="label">Duration</span>
          <div className="value fixed-width-duration">{Math.floor(item.actual_time_in_minutes / 60)+ " min, " + (item.actual_time_in_minutes - (Math.floor(item.actual_time_in_minutes / 60)*60)) + " sec"}</div>
        </section>
        <section>
          <span className="label">Start Date Time</span>
          <div className="value fixed-width-date">{item.start_date === null ? "Not added" : new Date(item.start_date).toDateString()+', '+new Date(item.start_date).toLocaleTimeString() }</div>
        </section>
        <section>
        <span className="label">End Date Time</span>
        <div className="value fixed-width-date">{item.end_date === null ? "Not added" : new Date(item.end_date).toDateString()+', '+new Date(item.end_date).toLocaleTimeString()}</div>
      </section>
        <section>
          <span className="label">Total Score</span>
          <div className="value fixed-width-duration">{item.score}</div>
        </section>
        <section>
        <span className="label">Interviewer</span>
        <div className="value fixed-width">{item.exam_creator}</div>
      </section>

        <section>
         <Link to={{pathname:`/me/exam/${item.id}`,state:item.id}} className="view_btn">View</Link>
        </section>
      </div>
    ));

    const applicationsNumberIndicator = (
      <span>
      {!this.state.exams.length ? (<div></div>) :
      <div>
        Showing{" "}
        {this.state.exams.length === 1
          ? " 1 exam "
          : this.state.exams.length + " exams "}
        out of {this.state.total_exams} 
        </div> }
        <div className="exam-filters">
          <div className="type-filter-container filter-item">
          <Radio active={this.state.selectedTypeFilter} data={["All", "Normal", "Restricted"]} label="Type" type={this.handleExamTypeFilter}
           />
          </div>
          <div className="col toggleSwitch filter-item">
            <p>Only My Exams</p>
            <label className="switch">
              <input type="checkbox" onClick={this.handleAuthor} />
              <span className="slider" />
            </label>
          </div>
        </div>
      </span>
    );

    return (
      <div className="applications-container">
        <div className="button-container">
          <Link to="/me/exams_list"></Link>
        </div>

        <div className="applications-page">
          {applicationsNumberIndicator}
          {this.state.exams.length > 0 && exams}
          {(this.state.exams.length === 0 && this.state.examsLoading) && (
            <ApplicationPlaceholder />
          )}
          {(this.state.exams.length === 0 && !this.state.examsLoading) && (
            <div className="emptyState">
              <div className="text">
                <h1>There are no exams</h1>
              </div>
              <img alt="avatar" src={emptyStateSVG} />
            </div>
          )}
        </div>
        
       
        <AddExam />

        <div className="pages">
          {back_arrows}
          {pages}
          {front_arrows}
        </div>
      </div>
    );
  }
}

class Submissions extends Component {
  render() {
    return <ExamsList />;
  }
}
export default withRouter(Submissions);