import React, { Component } from 'react'
import "../../scss/loader.scss"

class FullPageLoader extends Component{
    render(){
        return (
            <div className="page-mask">
                <div className="loader">
                </div>
            </div>
        );
    }
}
export default FullPageLoader
