import React from 'react';

class Validate extends React.Component{
  //Validate equality
  static equal(input, confirmation){
    if(input == confirmation)
      return true;
    else
      return false;
  }

  static length(input, length){
    if(input.length >= length)
      return true;
    else
      return false;
  }

  static max_length(input, length){
    if(input.length <= length)
      return true;
    else
      return false;
  }

  static not_empty(input){
    if(input != "")
      return true;
    else
      return false;
  }
  static validEmail(input){
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(input).toLowerCase());
  }

  //Validates that it matches only numbers
  static number(input){
    const number = String(input);
    if(number.match(/^[0-9]*$/))
      return true;
    else
      return false;
  }

  //Validates that it matches only letters
  static letters_only(input){
    const text = String(input);
    if(text.match(/^[a-zA-Z]+$/) || text == '')
      return true;
    else
      return false;
  }

  static password(input){
    const password = String(input);
    if(password.match(/(?=.*[a-zA-Z])(?=.*\d)/) && Validate.length(input, 7))
      return true;
    else
      return false;
  }
}

export default Validate;