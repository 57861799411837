import React from "react";
import ReactDOM from "react-dom";
import "./scss/index.scss";
import App from "./app.js";
import * as serviceWorker from "./serviceWorker";
//Redux utilities
import {Provider} from 'react-redux';
import Store from './redux/store';
//User Authentication
//import jwtDecode from 'jwt-decode';
import {setCurrentUser} from './actions/authActions';
import {API, attach_token} from './api';
import 'axios-progress-bar/dist/nprogress.css';
import { loadProgressBar } from 'axios-progress-bar';
import { options, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from "react-alert-template-basic";
import "./sentry";

loadProgressBar({}, API);

//Check if user is already logged in
if (localStorage.jwtToken) {
  const CryptoJS = require("crypto-js");
  const bytes    = CryptoJS.AES.decrypt(localStorage.jwtToken.toString(), 'secret key 123');
  const token    = bytes.toString(CryptoJS.enc.Utf8);
  attach_token(token,localStorage.uid,localStorage.client);
  Store.dispatch(setCurrentUser(localStorage.jwtToken,localStorage.uid,localStorage.client));
}

ReactDOM.render(
  <Provider store={Store}>
    <AlertProvider
      template={AlertTemplate}
      position={positions.BOTTOM_LEFT}
      timeout={5000}
      offset="50px"
    >
      <App />
    </AlertProvider>
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
