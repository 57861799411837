import axios from "axios";
import { logout } from "./actions/authActions";
let CryptoJS = require("crypto-js");

//Create Axios instance
const LOCAL_URL = process.env.REACT_APP_QB_LOCAL
export const DEFAULT_URL = `http://api.localhost:3000/api/v1/`
export const API = axios.create({
	baseURL: LOCAL_URL ? LOCAL_URL : DEFAULT_URL
});

//Attach auth token in headers
export function detach_token() {
  API.interceptors.request.use(function (req) {
    req.headers["access-token"] = "";
    req.headers["uid"] = "";
    req.headers["client"] = "";

    return req;
  });
}

export function attach_token(token, uid, client) {
  //Use axios interceptors to attach auth token
  API.interceptors.request.use(
    function (req) {
      if (localStorage.jwtToken) {
        const bytes = CryptoJS.AES.decrypt(
          localStorage.jwtToken.toString(),
          "secret key 123"
        );
        const token = bytes.toString(CryptoJS.enc.Utf8);
        req.headers["access-token"] = token;
        req.headers["uid"] = localStorage.uid;
        req.headers["client"] = localStorage.client;
      }
      return req;
    },
    function (error) {
      //In case of error:
      return Promise.reject(error);
    }
  );
  //Use axios interceptors to catch access-token
  API.interceptors.response.use(
    function (res) {
      if (localStorage.jwtToken && res.headers["access-token"] !== undefined) {
        const bytes = CryptoJS.AES.decrypt(
          localStorage.jwtToken.toString(),
          "secret key 123"
        );
        const token = bytes.toString(CryptoJS.enc.Utf8);
        if (
          token != res.headers["access-token"] &&
          res.headers["access-token"] !== ""
        ) {
          const encryptedToken = CryptoJS.AES.encrypt(
            res.headers["access-token"],
            "secret key 123"
          );
          //Set item in localStorage
          localStorage.setItem("jwtToken", encryptedToken.toString());
        }
      }
      return res;
    },
    function (error) {

      if (
        error.response.headers["access-token"] == null &&
        (error.response["status"] === 404 )
      ) {
        logout();
        return;
      }
      else if(error.response.headers["access-token"] == null && error.response["status"] === 401){
        return redirectToLogin();
      }

      else if ((localStorage.jwtToken && ( error.response["status"] === 404 || error.response["status"] === 422 ))|| (localStorage.jwtToken &&  error.response["status"] === 405)) {
        const bytes = CryptoJS.AES.decrypt(
          localStorage.jwtToken.toString(),
          "secret key 123"
        );
        const token = bytes.toString(CryptoJS.enc.Utf8);
        if (error.response.headers["access-token"] && token != error.response.headers["access-token"]) {
          const encryptedToken = CryptoJS.AES.encrypt(
            error.response.headers["access-token"],
            "secret key 123"
          );
          //Set item in localStorage
          localStorage.setItem("jwtToken", encryptedToken.toString());
        }
      }

      return Promise.reject(error);
    }
  );
}
function redirectToLogin(){ //we have to delete and redirect becasuse you cant logout a non existing user ...
  detach_token();
  localStorage.clear();
  window.location.href='/'
}
