import React from "react";
import { Route, Redirect, Switch } from 'react-router-dom';
import forgotPassword from "../components/root/forgot_Password";
import Reset from "../components/root/resetPassword";
import Login from "../components/root/login";
import "../scss/root.scss";
import eSpaceLogo from "../images/espace-logo.svg";

//Root path destinations routes
function RootRoutes() {
  return (
    <div className="content">
      <img src={eSpaceLogo} id="logo" alt="eSpace" />
      <div className="sidePic">
        <div className="welcome">
          <h1>QUESTIONS BANK</h1>
          <p>Welcome, please log in to continue.</p>
        </div>
      </div>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/forgot_password" component={forgotPassword} />
        <Route path="/reset/:token" component={Reset} />
        <Route path="/reset_password" component={Reset} />
        <Redirect path="*" to="/" />
        <Route component={Error} />
      </Switch>
    </div>
  );
}
export default RootRoutes;
