import React, { Component } from 'react';
import InputSection from "../FormElements";
import Radio from "../addQ/radio";
import "../../scss/myQuestions/filter.scss";
import { getTagsList } from '../../actions/questionActions.js';
import Error from '../error.js';
import AddCategory from '../addQ/AddCategory'
import Slider from '@material-ui/core/Slider';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

class Filter extends Component {
  tagsList = [];
  tagsText = "";
  tagsMap = new Map();
  constructor(props) {
    super(props);
    this.fillTagsList();
    this.state = {
      isOpen: false,
      searchKey: "",
      time: "",
      difficulty: "",
      tags: "",
      type: "",
      author: false,
      error: "",
      timeArr: [0,20]
    }
    this.handleChoice = this.handleChoice.bind(this);
    this.setTags = this.setTags.bind(this)
  }
  //get tags list from server
  fillTagsList = () => {
    let arr = [];
    getTagsList().then(res => {
      arr = res.data;
      for (let i = 0; i < arr.length; i++) {
        this.tagsMap.set(arr[i].title.toLowerCase(), arr[i].id);
        this.tagsList.push(arr[i].title);
      }
    });
  }
  //handles showing either all the questions or only my questions
  handleAuthor = (e) => {
    this.setState({ author: !this.state.author });
  }
  //handles showing the filter component
  handleClick = (e) => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  }
  //clears filter
  clearState = () => {
    this.setState({
      isOpen: false,
      time: "",
      difficulty: "",
      tags: "",
      type: "",
      author: false,
      error: "",
      active_type: false,
      active_difficulty: false,
      active_time: false,
      timeArr: [0,20]
    });
    let search = this.state.searchKey.trim();
    if (search != "") {
      for (let i = 0; i < search.length; i++) {
        if (search.charAt(i) == " " && i < search.length - 2) {
          if (search.charAt(i + 1) == " ") {
            search = search.substr(0, i) + search.substr(i + 1, search.length - 1);
            i--;
          }
        }
      }
    }
    let req = `/questions?title=${search}`;
    this.props.httpRequest(1, req);
  }

  setTags = (tags) => {
    this.setState({ tags: tags.length > 0 ? tags.join('/').toLowerCase() + '/' : ""})
  }

  //handles the search
  handleSearch = (e) => {
    this.setState({ error: "" });
    this.setState({ searchKey: e.target.value });
  }
  //handles choosing a filter
  handleChoice(value, field) {
    this.setState({ error: "" });
    let copy = this.state;
    copy[field] = value;
    let key = "active_" + field;
    copy[key] = value;
    this.setState({ copy });
  }

  componentWillReceiveProps() {
    //this.handleSubmit();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let err = '';
    let categories = this.state.tags.split('/');
    let categoryIds = [];
    categories.pop(); //empty space
    if (this.state.tags !== "" && this.state.tags.charAt(this.state.tags.length - 1) !== '/') {
      err = "Please insert / at the end of each category"
    }
    else {
      //handles duplicate categories
      for (let i = 0; i < categories.length; i++) {
        if (this.tagsMap.has(categories[i])) {
          if (!categoryIds.includes(this.tagsMap.get(categories[i]))) {
            categoryIds.push(this.tagsMap.get(categories[i]));
          }
          else {
            err = categories[i] + " is a duplicate category";
          }
        }
        else {
          //handles invalid categories
          categories[i] === "" ? err = "you have entered an empty space as a category" : err = categories[i] + " is not a valid category";
          break;
        }
      }
      if (categories.length == 0 && this.state.tags.length != "") {
        err = this.state.tags + " is not a valid category";
      }
    }
    this.setState({ error: err });
    if (err == "") {
      let search = this.state.searchKey.trim();
      //handles the search input
      if (search != "") {
        for (let i = 0; i < search.length; i++) {
          if (search.charAt(i) == " " && i < search.length - 2) {
            if (search.charAt(i + 1) == " ") {
              search = search.substr(0, i) + search.substr(i + 1, search.length - 1);
              i--;
            }
          }
        }
      }
      //appends filters to the request
      let req = `/questions?title=${search}`;
      if (this.state.difficulty !== "") {
        req = req + `&difficulty=${this.state.difficulty}`;
      }
      if (this.state.timeArr !== "") {
        req = req + `&time_from=${this.state.timeArr[0] * 60}` + `&time_to=${this.state.timeArr[1] * 60}`;
      }
      if (this.state.type !== "") {
        let q_type = this.state.type === "T/F" ? "true_false" : this.state.type.toLowerCase();
        req = req + `&question_type=${q_type}`;
      }
      if (categoryIds.length !== 0) {
        for (let i = 0; i < categoryIds.length; i++) {
          req = req + `&category_ids[]=${categoryIds[i]}`;
        }
      }
      if (this.state.author === true) {
        req = req + `&user_id=${JSON.parse(localStorage.userObject).id}`;
      }
      if (this.props.per_page !== undefined) {
        req = req + `&per_page=${this.props.per_page}`;
      }
      this.props.httpRequest(1, req);
    }

  }
  handleTimeRange = (e, value) =>{
    this.setState({timeArr: value});
  }

  render() {
    const filterContainer = this.state.isOpen ?
      <div className="filterOptions">
        <div className="col">
          <Radio active={this.state.active_type} label="Type" data={["MCQ", "Essay", "T/F"]} type={this.handleChoice} />
        </div>
        <div className="col">
          <Radio active={this.state.active_difficulty} label="Difficulty" data={["Easy", "Moderate", "Difficult"]} difficulty={this.handleChoice} />
        </div>
        <div className="time-div">
          <div className="radio">
        <p>
        Pick Time
        </p>
        <ThemeProvider theme={muiTheme}>
          <Slider
          value= {this.state.timeArr}
          onChange={this.handleTimeRange}
          valueLabelDisplay="auto"
          step = {5}
          min= {0}
          max= {20}
          aria-labelledby="range-slider"
          marks = {true}
        />
        </ThemeProvider>
        </div>
        </div>
        <div className="col category-filter">
          <AddCategory isFilter setCategories={this.setTags} />
        </div>
        <div className="col toggleSwitch">
          <p>Only my questions</p>
          <label className="switch">
            <input type="checkbox" onClick={this.handleAuthor} />
            <span className="slider" />
          </label>
        </div>

        <div className="row filterClear">
          <div onClick={this.clearState} className="filterTextUnderline">
            <svg className="filterClearIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
            <span className="filterClearText">Clear all filters</span>
          </div>
          <button onClick={this.handleSubmit} >
            <span>Apply</span>
          </button>

        </div>
      </div>
      : null;


    return (
      <form className="filter">
        {this.state.error === '' ? '' : <Error msg={this.state.error} type="error" />}

        <div className="row">
          <div className="search">
            <InputSection placeHolder="Search " onChange={this.handleSearch} />
            <button className="searchBtn" onClick={(e) => this.handleSubmit(e)} >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z" /></svg>
            </button>
          </div>
          <button onClick={this.handleClick} className={this.state.isOpen ? "active" : null}>
            <span> Filter </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.906 2.841c1.104-2.412-7.833-2.841-10.907-2.841-2.934 0-12.01.429-10.906 2.841.508 1.11 8.907 12.916 8.907 12.916v5.246l4 2.997v-8.243s8.398-11.806 8.906-12.916zm-10.901-.902c4.243 0 8.144.575 8.144 1.226s-3.9 1.18-8.144 1.18-8.042-.528-8.042-1.18 3.799-1.226 8.042-1.226z" /></svg>
          </button>
        </div>
        {filterContainer}

      </form>
    );
  }
}

const muiTheme = createMuiTheme({
  overrides:{
    MuiSlider: {
      thumb:{
      color: "#59B3D5",
      },
      track: {
        color: '#59B3D5',
      
      },
    }
}
});

export default Filter;
