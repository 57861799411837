import React, { Component } from "react";
import "../../scss/nav.scss";
import SVG from "../../svg.js";
import NavItem from "./navItem";
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { logout } from '../../actions/authActions.js';
import { withRouter } from 'react-router';
import eSpaceLogo from "../../images/espace-logo.svg";

export class Navigation extends Component {
  constructor(props, {alert}) {
    super(props);
    this.alert = alert
    this.state = {
      //navigation tabs
      links: [
        { key: 0, text: "My Questions", icon: "question", active: true, link: "/me/my_questions" },
        { key: 1, text: "Applications", icon: "task", active: false, link: "/me/applications" },
        { key: 3, text: "Exams", icon: "exam", active: false, link: "/me/exams_list"}
      ],
      name: this.props.first_name,
      img: this.props.avatar,
    }
    this.handleLogOut = this.handleLogOut.bind(this);

  }

  handleClick = (e) => {
  }
  //handles updating the name and picture
  componentWillReceiveProps(newProps) {
    this.setState({ name: newProps.first_name, img: newProps.avatar });
  }
  //handles logging out
  handleLogOut() {
    this.props.logout().then(
      (res) => { this.props.history.push('/') },
      (err) => {}
    );
  }

  render() {
    const items = this.state.links.map((item, key) =>
     (item.text === "My Questions" || item.text==="Exams")  && JSON.parse(localStorage.userObject).type === "Candidate" ? null : <NavItem key={key} text={item.text} icon={item.icon} link={item.link} clickListener={this.handleClick} id={item.key} />
    );
    const pic = this.state.img === "" ? <SVG name="user" /> : <img alt="Avatar" src={this.state.img} />;

    return (
      <div className="nav-container">
        <img src={eSpaceLogo} className="logo" alt="Logo" />
        <div className="links-container">
          {items}
        </div>
        <div className="profile-container">
          <div className="profile">
            <a  onClick={() => { this.props.toggleProfile() }}>
              <div id="user-pic">
                {pic}
              </div>
              <div id="user-name">{this.state.name}</div>
            </a>
            <div className="actions">
              <a href="#" onClick={this.handleLogOut}><SVG name="logout" /></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  logout: PropTypes.func.isRequired
}

export default withRouter(connect(null, { logout })(Navigation));
