import React, { Component } from "react";
import InputSection, { FormBtn } from "../FormElements.js";
import validate from "../form_validations.js";
import { API ,detach_token} from "../../api";
import "../../scss/root/resetPassword.scss";
import ErrorPNG from '../../images/error.png'
const url_params = require("query-string");

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        password: "",
        password_confirm: "",
      },
      invalid_token: false,
      token_checked: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    if (localStorage.client) {
      this.logOutifSignedIn().then(res => {
           
        //Remove token from localStorage
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('uid');
        localStorage.removeItem('client');
        localStorage.removeItem('userObject');
        localStorage.removeItem('redirect_url');
      })
    }
  }

  redirectInFiveSecond(){
    setTimeout(() => {
      this.props.history.push("/");
    }, 5000);
  }

  componentDidMount() {
    let decoded_token;
    if (this.props.match.params.token !== undefined) {
      try{
        decoded_token = JSON.parse(atob(this.props.match.params.token));
      }catch{
        this.setState({token_checked: true, invalid_token: true})
        this.redirectInFiveSecond()
        return
      }
      this.setState({
        //Parse the invitation object token
        //Test token: eyJpbnZpdGF0aW9uX3Rva2VuIjoiZTF5akJWOGV5ejNjTnk4Q0hZV3YiLCJlbWFpbCI6IndhZWx3YWVAZW9wd2FlLmNvbSIsInR5cGUiOiJJbnRlcnZpZXdlciJ9
        parameters: decoded_token,
      });
      API.post(`auth/invitation/check`, {
        token: decoded_token.invitation_token,
      }).catch(() => {
        this.setState({ invalid_token: true });
        this.redirectInFiveSecond()
      }).finally(() => {
        this.setState({token_checked: true})
      });
    } else {
      let data = url_params.parse(this.props.location.search);
      this.setState({
        parameters: data,
        token_checked: true
      });
    }
  }
  logOutifSignedIn=()=>{
        detach_token();
        return API.delete(`auth/sign_out`);
    }
  handleChange(event, field) {
    //Make a copy of user object
    let copy = this.state.user;
    //Add value from text field to corresponding key
    copy[field] = event.target.value;
    this.setState(copy);
  }

  submitForm() {
    //Get password/password confirmation from state
    let password = this.state.user.password;
    let password_confirm = this.state.user.password_confirm;

    let user = {
      password: password,
      password_confirmation: password_confirm,
    };
    if (this.props.match.params.token !== undefined) {
      user.invitation_token = this.state.parameters.invitation_token;
    }
    this.setState({ user: user });
    //Validate data using validation component
    //Restrictions:
    //Length: 7, not empty, contains a single letter or digit atleast.
    if (validate.equal(password, password_confirm)) {
      if (validate.not_empty(password)) {
        if (validate.length(password, 7)) {
          //Send API request
          if (validate.password(password)) {
            if (this.props.match.params.token !== undefined) {
              let resource = this.state.parameters.type + "s";
              resource = resource.charAt(0).toLowerCase() + resource.slice(1);
              API.put(`auth/${resource}/invitation`, user).then(() => {
                alert("You were succesfully registered!");
                this.props.history.push("/");
              });
            } else {
              const headers = {
                "access-token": this.state.parameters["access-token"],
                client: this.state.parameters.client_id,
                uid: this.state.parameters.uid,
              };
              API.put(`auth/password`, user, { headers: headers })
                .then((res) => {
                  alert("Password was reset.");
                  this.props.history.push("/");
                })
                .catch((err) => {
                  alert("Error!");
                });
            }
          } else {
            alert(
              "Error: password must atleast contain a single digit or single letter"
            );
          }
        } else {
          alert("Error: password must be atleast 7 digits");
        }
      } else {
        alert("Error: password should not be empty");
      }
    } else {
      alert("Error: password doesn't match");
    }
  }

  render() {
    let parameters, data, resetPage;
    
    if (!this.state.token_checked) {
      resetPage = null;
    } else if (this.state.invalid_token) {
      resetPage = (
        <div className="expired-container">
          <span className="expired-text">
            Invitation Link is Expired&nbsp;<img alt="eSpace" src={ErrorPNG}></img>
          </span>
          <span className="expired-5-seconds">You'll be automatically redirected to login page in 5 seconds</span>
        </div>
      );
    } else {
      if (this.props.match.params.token !== undefined) {
        parameters = JSON.parse(atob(this.props.match.params.token));
      } else {
        data = url_params.parse(this.props.location.search);
      }
      resetPage = (
        <form autoComplete="off">
          <InputSection
            disabled={true}
            type="Email"
            label=" "
            defaultValue={
              this.props.match.params.token !== undefined
                ? parameters.email
                : data.uid
            }
          />
          <InputSection
            type="Password"
            name="password"
            label="New Password"
            onChange={this.handleChange}
          />
          <InputSection
            type="Password"
            name="password_confirm"
            label="Confirm Password"
            onChange={this.handleChange}
          />
          <FormBtn label="Continue to Dashboard" onClick={this.submitForm} />
        </form>
      );
    }
    return <div className="formContainer root-form">{resetPage}</div>;
  }
}

export default ResetPassword;
