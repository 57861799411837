import React, { Component } from "react";
import "../../scss/exam/exam.scss";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { isValidURL } from "../../utils";

class Question extends Component {
  constructor(props) {
    super(props);
    this.questionsContainer = React.createRef();
    this.state = {
      currentQuestion: 1,
      isLightboxOpen: false,
      selectedQuestionImage: ""
    };
    /* mark questions that have answer as done */
    this.props.questions.map((item, i) => {
      if (this.props.answerMap.has(item.id)) {
        this.props.statusHandler(i, true);
      }
    })
  }

  mcqchecked = (i, e, id, questionID, singleAnswer, type) => {
    //UI Handling
    let choicesList = document.querySelector("#question" + (i + 1))
      .childNodes[0].childNodes[2];
    let isDone = false;
    for (let count = 0; count < choicesList.length; count++) {
      if (choicesList[count].checked) {
        isDone = true;
        break;
      } else {
        continue;
      }
    }
    this.props.statusHandler(i, isDone);
    //Answer handling
    let answer = {
      question_id: questionID,
      choice_id: id,
    };
    if(type === "true_false"){
      answer={
      question_id: questionID,
      answer: id
      };
    }
    if (e.target.checked === true) {
      this.props.submitAnswer(answer, type, singleAnswer);
    } else {
      this.props.removeAnswer(id, type);
    }
  };

  essayChecked = (i, e, questionID) => {
    //UI handling
    let textarea = document.querySelector("#question" + (i + 1)).childNodes[0]
      .childNodes[2].childNodes[0];
    let isDone = false;
    isDone = textarea.value === "" ? false : true;
    this.props.statusHandler(i, isDone);
    //Answer handling
    let answer = {
      question_id: questionID,
      essay_text: e.target.value,
    };
    if (e.target.value !== "") {
      this.props.submitAnswer(answer, "essay");
    } else {
      this.props.removeAnswer(questionID, "essay");
    }
  };

  handleScroll = () => {
    const scrollTop = this.questionsContainer.current.scrollTop;
    let element = document.getElementById("question1");
    let question =
      Math.ceil(scrollTop / element.clientHeight) === 0
        ? 1
        : Math.ceil(scrollTop / element.clientHeight);
    if (this.props.currentQuestion !== question) {
      this.props.setQuestion(question);
    }
  };

  render() {
    const questions = this.props.questions.map((item, i) => {
      let answers = null;
      let title = [];
      title = item.title.split("\n").map((i) => {
        let splittedQuestion = i.split(" ");
        let result = "";
        splittedQuestion.forEach(function (segment) {
          let word = "";
          if (!isValidURL(segment)) {
            let splittedWord = segment.split("");
            splittedWord.forEach(function (char) {
              word += `<span class="me-2">${char}</span>`
            })
          }
          result += isValidURL(segment)
            ? `<a href=${segment} target="_blank">${segment}</a>`
            : `${word} `;
      });
        return <div dangerouslySetInnerHTML={{__html: result}}/>;
      });
      if (item.choices_attributes.length === 0 && item.question_type === "essay") {
        answers = (
          <textarea onChange={(e) => this.essayChecked(i, e, item.id)} value={this.props.answerMap.get(item.id)}/>
        );

      } else {
        if(item.question_type === "true_false"){
          item.choices_attributes = [{choice_text: "True", id:1, question_id: item.id},{choice_text: "False", id:0, question_id: item.id}]
          item.has_single_answer = true;
        }
          answers = item.choices_attributes.map((choice, j) => {
            const checkedMCQ = item.question_type === "mcq" &&
              this.props.answerMap.has(item.id) &&
              this.props.answerMap.get(item.id).has(choice.id);
            return (
              <div className="question-choice" key={j} ref={this.myRef}>
                <input
                  onClick={(e) =>
                    this.mcqchecked(
                      i,
                      e,
                      choice.id,
                      choice.question_id,
                      item.has_single_answer,
                      item.question_type
                    )
                  }
                  name={item.has_single_answer ? "questionOption" + i : ""}
                  type={item.has_single_answer ? "radio" : "checkbox"}
                  id={(item.has_single_answer ? "radio" : "check") + j + "q" + i}
                  checked={
                    checkedMCQ
                      ? true
                      : this.props.answerMap.get(item.id) == choice.id
                      ? true
                      : false
                  }
                />
                <label for={(item.has_single_answer ? "radio" : "check") + j + "q" + i}>
                  {choice.choice_text}
                </label>
              </div>
            );
          });
      }
      return (
        <div className="exam-question" id={"question" + (i + 1)} key={i + 1}>
          <div className="question-body">
            <p>{title}</p>
            {
              item.question_image ?
              (<span
                className="question-img-sm"
                onClick={() =>
                  this.setState({
                    isLightboxOpen: true,
                    selectedQuestionImage: item.question_image,
                  })
                }
              >
                <span className="material-icons">
                  insert_photo
                </span>
                View Image
              </span>):
              // Removing this span will break mcqchecked function
              // TODO: refactor mcqchecked function
              <span></span>
            }
            <form>
              {item.question_type === "mcq" && !item.has_single_answer ? (
                <span className="multiple-tip">
                  * You can choose multiple answers
                </span>
              ) : null}
              {answers}
            </form>
          </div>
          {
            item.question_image ?
            <img onClick={() => this.setState({isLightboxOpen: true, selectedQuestionImage: item.question_image})} src={item.question_image} className="question-img-lg"/>
            :
            // Removing this span will break mcqchecked function
            // TODO: refactor mcqchecked function
            <span></span>
          }
        </div>
      );
    });

    return (
      <div
        className="question-container"
        ref={this.questionsContainer}
        onScroll={this.handleScroll}
      >
        {questions}
        {this.state.isLightboxOpen && (
          <Lightbox
            mainSrc={this.state.selectedQuestionImage}
            onCloseRequest={() => this.setState({ isLightboxOpen: false, selectedQuestionImage: "" })}
          />
        )}
      </div>
    );
  }
}

export default Question;
