import React, { Component } from "react";
import ProfileEdit from "./edit";
import ProfileShow from "./show";
import {API} from '../../api';

class Profile extends Component {
  constructor(props){
    super(props);
    this.state = {
      editMode : false,
      image_preview_url: '',
      err: '',
      user:localStorage.userObject? JSON.parse(localStorage.userObject):null
    }
  
   
    this.handleShow = this.handleShow.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.updateUserFromAPI();
  }

  updateUser(){

    this.setState({user:JSON.parse(localStorage.userObject)},()=>this.handleShow())
    
  }

  updateUserFromAPI(){
    API.get(`users/${this.state.user.id}`).then((res) => {
      this.setState({user:res.data})
    });
  }

  handleEdit(e){
    this.setState({ editMode:true });
  }

  handleShow(e){
    this.setState({ editMode:false });
  }

  render(){
    if(this.props.hidden == false){
      return(
        this.state.editMode ? <ProfileEdit updateNavigation={this.props.updateNavigation} updateUser={this.updateUser} handleShow={this.handleShow} first_name={this.state.user.first_name} last_name={this.state.user.last_name}
       position={this.state.user.title} email={this.state.user.email} mobile_number={this.state.user.mobile_number} error="" avatar={this.state.user.avatar.url} type={this.state.user.type}/> :

         <ProfileShow toggleProfile={this.props.toggleProfile} handleEdit={this.handleEdit.bind(this)} first_name={this.state.user.first_name} last_name={this.state.user.last_name}
        position={this.state.user.position} email={this.state.user.email} mobile_number={this.state.user.mobile_number} avatar={this.state.user.avatar.url}/>
      );
    }else{
      return null;
    }
  }
}

export default Profile;
