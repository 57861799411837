import React from 'react';
import InputSection, { FormBtn } from "../FormElements.js";
import Validate from "../form_validations";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forgot_password } from '../../actions/authActions.js';
import Error from '../error.js';
export class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      error: '',
      link: `${window.location.origin}/reset_password`
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    //redirects user if already logged in
    if (localStorage.client) {
      if (JSON.parse(localStorage.userObject).type === "Candidate") {
        this.props.history.replace("/me/applications");
      }
      else {
        this.props.history.replace('/me/my_questions');
      }
    }
  }
  //handles change in input fields
  handleChange(event, field) {
    let copy = this.state;
    copy[field] = event.target.value;
    this.setState({ copy });
    this.setState({ error: '' });
  }
  handleSubmit = event => {
    let error = '';
    if (!Validate.not_empty(this.state.email)) {
      error = 'Please fill in the Email field';
    }
    else if (!Validate.validEmail(this.state.email)) {
      error = 'Email is not valid';
    }
    this.setState({ error: error });
    if (error === '') {
      const user = {
        email: this.state.email,
        redirect_url: this.state.link
      }
      this.props.forgot_password(user).then(
        (res) => this.props.history.push('/'),
        (err) => this.props.history.push('/')
      );
    }
  }
  render() {
    return (
      <div className="formContainer root-form">
        {this.state.error === '' ? '' : <Error msg={this.state.error} type="error" />}
        <form autoComplete="off" method="post" noValidate>
          <InputSection type="text" label="Email" name="email" onChange={this.handleChange} />
          <FormBtn label="Send Email" onClick={this.handleSubmit} />
        </form>
      </div>
    )


  }
}
ForgotPassword.propTypes = {
  forgot_password: PropTypes.func.isRequired
}

export default connect(null, { forgot_password })(ForgotPassword);
