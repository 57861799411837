import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashboardRoutes from "./routes/dashboard";
import RootRoutes from "./routes/root";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { attach_token } from './api.js';

function App() {
  return (
    <div className="content">
      <BrowserRouter>
        <Switch>
          <Route path="/me" component={DashboardRoutes} />
          <Route path="/" component={RootRoutes} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

App.propTypes = {
  attach_token: PropTypes.func.isRequired
}

export default connect(null, { attach_token })(App);
