import React, {Component} from "react";
import "../../scss/myQuestions/level.scss";

class Level extends Component{
  constructor(props){
		super(props);
		this.getElem = this.getElem.bind(this);
	}
	getElem(){
		let elem=[];
		switch(this.props.level){
			case "Difficult":
				elem = ["red", "red", "red"];
				break;
			case "Moderate":
				elem = ["yellow", "yellow", "grey"];
				break;
			case "Easy":
				elem = ["green","grey","grey"];
				break;
			default:
				elem = ["grey","grey","grey"];
		}
		return elem;
	}

	render(){
		const elemProps = this.getElem(this.props.level);
		return(
			<div className="question-level">
				<div className={"bar "+ elemProps[0]}/>
				<div className={"bar "+ elemProps[1]} />
				<div className={"bar "+ elemProps[2]} />
			</div>
		);
	}
}
export default Level;
