import React, { Component } from "react";
import "../../scss/addQ/choices.scss";

class TFChoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      t_f: "false",
    };
    this.toggleActive = this.toggleActive.bind(this);
  }

  //handles choosing the correct answer
  toggleActive(e, TFLabel) {
    this.handleCorrectTF(TFLabel);
  }

  //handles true_false state
  handleCorrectTF = (TFLabel) => {
    this.setState({ t_f: TFLabel }, () => this.props.passState(this.state.t_f));
  };

  // updating elements before every render
  static getDerivedStateFromProps(nextProps) {
    return {
      elements: nextProps.elements,
    };
  }

  render() {
    let items = this.state.elements.map((item, i) => (
      <div className="choice-container">
        <div
          className={"choice-tf " + (item.is_correct ? "choice-active" : " ")}
          id={item.id}
          key={i}
          onClick={(e) => this.toggleActive(e, item.choice_text)}
        >
          {item.choice_text}
        </div>
      </div>
    ));

    return (
      <div className="items-container">
        <div className="choices">{items}</div>
      </div>
    );
  }
}
export default TFChoices;
